/* eslint-disable no-underscore-dangle */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UpdateSettingsData} from 'shared/gql/Settings/types';

export type SettingState = {
  data: {
    covidVaccineRequired: boolean;
    allowSingleDoseVaccine: boolean;
    covidMedicalExemption: boolean;
    covidReligiousExemption: boolean;
    covidStatus: string;
    orientationMaterials?: {
      src?: string;
      title: string;
      data?: string;
      uuid?: string;
    };
  };
  loading: boolean;
  error: string | null;
};

const initialState: SettingState = {
  data: {
    covidVaccineRequired: true,
    allowSingleDoseVaccine: false,
    covidMedicalExemption: false,
    covidReligiousExemption: false,
    covidStatus: '',
    orientationMaterials: undefined,
  },
  loading: false,
  error: null,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    getSettingsData: state => {
      state.loading = true;
      state.error = null;
    },
    setSettingsData: (state, action: PayloadAction<UpdateSettingsData>) => {
      const {orientationMaterials} = action.payload;
      state.data = {
        ...state.data,
        ...action.payload,
        orientationMaterials :orientationMaterials ?  {
          data: orientationMaterials.data,
          src: orientationMaterials.src,
          title: orientationMaterials?.title,
          uuid: orientationMaterials?.uuid,
        } : undefined,

      };
      state.loading = false;
      state.error = null;
    },
  },
});

export const {getSettingsData, setSettingsData} = settingsSlice.actions;

export default settingsSlice.reducer;
