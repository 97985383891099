import {MutationHookOptions, gql, useMutation} from '@apollo/client';
import {useApolloClients} from '../../../core/providers/ApolloContextProvider';
import useAppDispatch from '../../hooks/useAppDispatch';
import { setSettingsData } from '../../slices/settingsSlice';

const updateLagacySettings = () => gql`
  mutation updateFacility(
    $id: Int
    $covidMedicalExemption: Boolean
    $covidReligiousExemption: Boolean
    $covidStatus: String
    $orientationMaterials: TypeFacilityOrientationMaterialsInput
    $covidVaccineRequired: Boolean
    $allowSingleDoseVaccine: Boolean
  ) {
    data: updateFacility(
      id: $id
      covidMedicalExemption: $covidMedicalExemption
      covidReligiousExemption: $covidReligiousExemption
      covidStatus: $covidStatus
      orientationMaterials: $orientationMaterials
      covidVaccineRequired: $covidVaccineRequired
      allowSingleDoseVaccine: $allowSingleDoseVaccine
    ) {
      id
      covidStatus
      orientationMaterials
        {
          data
          src
          uuid
          title
        }
      covidMedicalExemption
      covidReligiousExemption
      covidVaccineRequired
      allowSingleDoseVaccine
    }
  }
`;

const useUpdateLagacySettings = (options?: MutationHookOptions<any, any>) => {
  const {legacyClient} = useApolloClients();
  const dispatch = useAppDispatch();

  return useMutation<any, any>(updateLagacySettings(), {
    ...options,
    client: legacyClient,
    onCompleted: data => {
      dispatch(setSettingsData(data?.data));
    },
    onError: err => {
      console.log('err from updateSettings', err);
    },
  });
};

export default useUpdateLagacySettings;
