/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react';

import {useFlags} from 'launchdarkly-react-client-sdk';
// Form validation
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

// Chakra
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  Text,
  Input,
  Box,
  AlertIcon,
  AlertDescription,
  Alert,
  Spinner,
  FormLabel,
  Switch,
  Tooltip,
  Icon,
} from '@chakra-ui/react';

// Moment
import moment from 'moment';
import 'moment-timezone';

// GQL
import {LiaInfoCircleSolid} from 'react-icons/lia';
import {MdOutlineHistory} from 'react-icons/md';
import useAppSelector from '../../../shared/hooks/useAppSelector';
import {
  useCreateShiftTemplate,
  useUpdateShiftTemplate,
  useDeleteShiftTemplate,
} from '../../../shared/gql/shiftTemplates/mutations';
import {newTemplateClosed} from '../../../shared/slices/shiftTemplateSlice';
import useAppDispatch from '../../../shared/hooks/useAppDispatch';

// Helpers
import {days, getFullDayName} from '../constants';
import {getShiftTemplateNames, getDefaultValues, getSchema} from './utils/helperFunctions';
import {
  getSelectedUnitPositions,
  getSelectedPositionQualifications,
  checkRateOutOfRange,
  getSelectedPositionBaseRate,
  getFirstPositionSelectedDescriptionAndInstructions,
  handleBonusChange,
  handleHasBonus,
  handleBreakSelection,
  handleDecrementNumOfOpenings,
  handleIncrementNumOfOpenings,
  handleDaySelection,
  checkOpeningButNoDay,
  handleDeleteDay,
  getQualificationsDefaultValues,
  getGroupedQualificationOptions,
  getMissingIds,
  getCurrentQualifications,
  getCurrentBreakValues,
} from '../helperFunctions';

import {useFacilityId, useTimeZone} from '../../Shifts/CreateShift/appSelectors';

import {formatToDollarAmount} from '../../../shared/utils/helperFunctions';

// Components
import UnitSelector from '../../../shared/components/UnitSelector/UnitSelector';
import PositionSelector from '../../../shared/components/PositionSelector/PositionSelector';
import DaySelector from '../../../shared/components/DaySelector/DaySelector';
import BreakSelector from '../../../shared/components/BreakSelector/BreakSelector';
import OpeningsSelector from '../../../shared/components/OpeningsSelector/OpeningsSelector';
import DescriptionInput from '../../../shared/components/DescriptionInput/DescriptionInput';
import RateSelector from '../../../shared/components/RateSelector/RateSelector';
import QualificationsSelector from '../../../shared/components/QualificationsSelector/QualificationsSelector';
import BonusSelector from '../../../shared/components/BonusSelector/BonusSelector';
import TimeSelector from '../../../shared/components/TimeSelector/TimeSelector';
import ShiftTemplateFooter from './ShiftTemplateFooter/ShiftTemplateFooter';

// Types
import {SingleValue, PositionRate} from '../ShiftTemplates.types';
import {Unit} from '../../../shared/types/unit';
import {Position} from '../../../shared/types/position';
import {Qualification} from '../../../shared/types/qualifications';
import {Props, FormValues} from './TemplateForm.types';
import {ReduxState} from '../../../shared/types/index';
import {
  trackShiftTemplateCreate,
  trackShiftTemplateEditView,
  trackTemplateView,
} from '../../../shared/mixpanel/page-views';
import ActivityHistoryDrawer from '../../../shared/components/ActivityHistory/ActivityHistoryDrawer';

const TemplateForm: React.FC<Props> = ({handleIsNewTemplateModalOpen, toast, reset}) => {
  const {showRates, allowMultiPositions} = useFlags();
  const facilityId = useFacilityId();
  const timeZone = useTimeZone();
  const shiftTemplates = useAppSelector((state: ReduxState) => state.shiftTemplates);
  const shiftTemplateNames = getShiftTemplateNames(shiftTemplates.data);
  const shiftTemplate = useAppSelector((state: ReduxState) => state.shiftTemplates.shiftTemplate);
  const facilityUnits = useAppSelector(
    (state: ReduxState) => state.shiftTemplates.facilityUnits?.getFacilityUnitPositionRate.units
  );
  const otherQualifications = useAppSelector(
    (state: ReduxState) => state.shiftTemplates.facilityUnits?.getFacilityUnitPositionRate.qualifications
  );
  const [isEditable, setIsEditable] = React.useState(false);
  const [hasBonus, setHasBonus] = React.useState<boolean>(false);
  const [isMulti, setIsMulti] = React.useState<boolean>(false);
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const dispatch = useAppDispatch();

  const isInternalAdmin = useAppSelector(
    state => state?.auth?.decodedToken[`https://nursedash.com/custom`]?.canAssumeIdentity
  );

  const positions: Position[] | undefined = shiftTemplate
    ? shiftTemplate.content.positions.map((position: Position) => ({
        name: position.name,
        rate: position.rate,
        adjustmentRate: position.adjustmentRate,
        id: position.id,
      }))
    : undefined;

  const defaultValues = getDefaultValues(shiftTemplate, positions);
  const schema = getSchema(isEditable, shiftTemplateNames);

  const {
    handleSubmit,
    setValue,
    register,
    getValues,
    watch,
    control,
    formState: {errors, dirtyFields, isSubmitSuccessful, isSubmitting},
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onTouched',
  });

  const handleEditClick = (
    e: /* eslint-disable @typescript-eslint/no-unused-expressions */
    React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setIsEditable(true);
  };

  const handleClose = () => {
    onClose();
    handleIsNewTemplateModalOpen(false);
  };

  const handleUnitSelection = (unit: SingleValue) => {
    setValue('qualifications', [], {shouldValidate: !!shiftTemplate});
    setValue('unit', unit, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
    setValue('position', []);
  };

  const isInCardData = (name: string) =>
    shiftTemplate?.content.positions.some((positionObject: Position) => positionObject.name === name);

  const unitName = watch('unit')?.label;
  const selectedUnitPositions = getSelectedUnitPositions(facilityUnits, unitName);

  const selectedPositionQualifications = getSelectedPositionQualifications({
    selectedUnitPositions,
    getValues,
  });

  const firstPositionSelectedDescriptionAndInstructions =
    getFirstPositionSelectedDescriptionAndInstructions(selectedPositionQualifications);

  const selectedPositionBaseRate: PositionRate[] | undefined =
    getSelectedPositionBaseRate(selectedPositionQualifications);

  const unitOptions = facilityUnits?.map((unit: Unit) => ({
    value: unit.id,
    label: unit.name,
  }));

  const groupedQualificationOptions =
    selectedPositionQualifications &&
    getGroupedQualificationOptions(selectedPositionQualifications, otherQualifications);

  const currentBreakValues = getCurrentBreakValues(watch, selectedPositionQualifications);

  const missingIds = getMissingIds(watch, groupedQualificationOptions);

  const openingButNoDay = checkOpeningButNoDay(watch, getValues);

  const positionIds = getValues('position')?.map((position: Position) => position.id);

  const isRateOutsideOfRange =
    selectedPositionBaseRate && checkRateOutOfRange(getValues('position'), selectedPositionBaseRate);

  const startTimeValueToConvert = getValues('startTime');
  const endTimeValueToConvert = getValues('endTime');

  const standardOffset = moment.tz('2024-10-01', timeZone).utcOffset();

  // const startTimeInUsersTimeZone = moment.tz(startTimeValueToConvert, 'HH:mm', `${timeZone}`);
  // const endTimeInUsersTimeZone = moment.tz(endTimeValueToConvert, 'HH:mm', `${timeZone}`);

  // const utcStartTime = startTimeInUsersTimeZone.clone().utc();
  // const utcEndTime = endTimeInUsersTimeZone.clone().utc();

  // const utcStartTimeString = utcStartTime.format('HH:mm');
  // const utcEndTimeString = utcEndTime.format('HH:mm');

  const utcStartTime = moment(startTimeValueToConvert, 'HH:mm').subtract(standardOffset, 'minutes');
  const utcEndTime = moment(endTimeValueToConvert, 'HH:mm').subtract(standardOffset, 'minutes');

  const utcStartTimeString = utcStartTime.format('HH:mm');
  const utcEndTimeString = utcEndTime.format('HH:mm');

  const shiftTemplatePayload = {
    facilityId,
    name: `${watch('templateName')}`,
    content: {
      ...(getValues('break') && getValues('break') !== 'No break' && {break: `${getValues('break')}`}),
      unit: {
        id: `${getValues('unit')?.value}`,
        name: `${getValues('unit')?.label}`,
      } as Unit,
      positions: watch('position')?.map((position: Position) => ({
        ...position,
        rate: typeof position.rate === 'string' ? Number(position.rate) * 100 : position.rate,
      })),
      bonusAmount:
        shiftTemplate && !dirtyFields.bonus ? Number(getValues('bonus')) : Math.round(Number(getValues('bonus')) * 100),
      qualifications: watch('qualifications') as Qualification[],
      startTime: utcStartTimeString,
      endTime: utcEndTimeString,
      openings:
        getValues('day') === undefined || getValues('numOfOpenings') === 0
          ? []
          : [
              {
                dayOfWeek: `${getValues('day')}`,
                total: getValues('numOfOpenings'),
              },
            ],
      description:
        !shiftTemplate &&
        !getValues('description')?.length &&
        firstPositionSelectedDescriptionAndInstructions?.description
          ? firstPositionSelectedDescriptionAndInstructions.description
          : `${watch('description')}` || '',
      clockInInstruction:
        !shiftTemplate &&
        !getValues('clockInInstruction')?.length &&
        firstPositionSelectedDescriptionAndInstructions?.clockInInstruction
          ? firstPositionSelectedDescriptionAndInstructions.clockInInstruction
          : `${watch('clockInInstruction')}` || '',
      clockOutInstruction:
        !shiftTemplate &&
        !getValues('clockOutInstruction')?.length &&
        firstPositionSelectedDescriptionAndInstructions?.clockOutInstruction
          ? firstPositionSelectedDescriptionAndInstructions.clockOutInstruction
          : `${watch('clockOutInstruction')}` || '',
    },
    unitId: `${getValues('unit')?.value}`,
    positionIds,
    ...(shiftTemplate && {shiftTemplateId: shiftTemplate?.id}),
  };

  const [createShiftTemplateFn, {loading: createIsLoading}] = useCreateShiftTemplate({
    variables: {
      cst: shiftTemplatePayload,
    },
  });

  const [updateShiftTemplateFn, {loading: updateIsLoading}] = useUpdateShiftTemplate({
    variables: {
      shiftTemplateInput: shiftTemplatePayload,
    },
  });

  // ToDo: fix any
  const onSubmit = async (formData: any) => {
    try {
      if (missingIds?.length === 0) {
        if (shiftTemplate) {
          const updateShiftTemplateResponse = await updateShiftTemplateFn({
            variables: {shiftTemplateInput: shiftTemplatePayload},
          });
          if (updateShiftTemplateResponse.data) {
            toast({
              title: 'Changes saved successfully',
              description: 'Your shift template was updated',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
            handleIsNewTemplateModalOpen(false);
            dispatch(newTemplateClosed);
          } else if (!updateShiftTemplateResponse.data) {
            toast({
              title: 'Error',
              description: 'Something went wrong',
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          }
        } else {
          const createShiftTemplateResponse = await createShiftTemplateFn({variables: {cst: shiftTemplatePayload}});
          if (createShiftTemplateResponse.data) {
            toast({
              title: 'Template created successfully',
              description: 'Your shift template was created',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
            handleIsNewTemplateModalOpen(false);
            dispatch(newTemplateClosed);
          } else if (!createShiftTemplateResponse.data) {
            toast({
              title: 'Error',
              description: 'Something went wrong',
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          }
        }
      }
    } catch (formError) {
      console.log('formError: ', formError, 'formData: ', formData);
    }
  };

  const onError = (formError: any) => {
    console.log('error: ', formError);
  };

  const [deleteShiftTemplateFn, {loading: deleteIsLoading, error: errorFromDelete}] = useDeleteShiftTemplate({
    variables: {
      shiftTemplateId: shiftTemplate?.id,
      facilityId: shiftTemplate?.facilityId,
    },
  });

  const handleDeleteShiftTemplate = async () => {
    try {
      const deleteShiftTemplateResponse = await deleteShiftTemplateFn();
      deleteShiftTemplateResponse.data &&
        toast({
          title: 'Template deleted',
          description: 'Your shift template was deleted',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      handleClose();
      dispatch(newTemplateClosed);
    } catch (error) {
      console.log(error);
    }
    errorFromDelete &&
      toast({
        title: 'Error',
        description: 'Something went wrong',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
  };

  const breakDefaultValues =
    (shiftTemplate?.content.break && {
      value: shiftTemplate?.content.break,
      label: shiftTemplate?.content.break,
    }) ||
    undefined;

  const qualificationsDefaultValues = getQualificationsDefaultValues(shiftTemplate);

  const currentQualifications = getCurrentQualifications(watch, qualificationsDefaultValues);

  useEffect(() => {
    onOpen();
    isSubmitSuccessful && reset();
    shiftTemplate?.content.bonusAmount && shiftTemplate.content.bonusAmount > 0 && setHasBonus(true);
    if (allowMultiPositions && getValues('position')?.length > 1) {
      setIsMulti(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpen, reset, isSubmitSuccessful, shiftTemplate?.content.bonusAmount, shiftTemplate]);

  const formIsDirty = Object.keys(dirtyFields).length !== 0;

  const startShiftTime = moment(watch('startTime'), 'HH:mm A');
  const endShiftTime = moment(watch('endTime'), 'HH:mm A');
  type BreakMap = {
    [key: string]: string;
  };
  const breakMap: BreakMap = {
    undefined: '00:00',
    '0mins': '00:00',
    '15mins': '00:15',
    '30mins': '00:30',
    '45mins': '00:45',
  };

  const breakDuration = moment.duration(breakMap[watch('break')] || '01:00');

  const duration = moment.duration(endShiftTime.diff(startShiftTime));

  const totalShiftTime = duration.subtract(breakDuration);

  if (totalShiftTime.asMilliseconds() < 0) {
    totalShiftTime.add(24, 'hours');
  }

  const longShiftAlert = totalShiftTime.minutes()
    ? `${totalShiftTime.hours()} hours and ${totalShiftTime.minutes()} minutes`
    : `${totalShiftTime.hours()} hours`;

  useEffect(() => {
    if (!shiftTemplate) {
      trackShiftTemplateCreate();
      return;
    }

    if (isEditable) {
      trackShiftTemplateEditView(shiftTemplate, shiftTemplate.id);
    }

    trackTemplateView(shiftTemplate, shiftTemplate.id);
  }, [isEditable, shiftTemplate]);

  const {isOpen: isHistoryDrawerOpen, onOpen: onHistoryDrawerOpen, onClose: onHistoryDrawerClose} = useDisclosure();

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={() => {
        handleClose();
      }}
      size={['s', '4xl']}
    >
      <ModalOverlay />
      <ModalContent>
        {createIsLoading ||
          updateIsLoading ||
          (deleteIsLoading && (
            <Flex margin="0" position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
              <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#38427E" size="xl" />
            </Flex>
          ))}
        <ModalBody padding="0" backgroundColor="#E8EAF1">
          <form onSubmit={handleSubmit(onSubmit, onError)} noValidate>
            <Flex flexDirection="column" marginTop={['3rem', 'inherit']}>
              <ModalCloseButton size="lg" onClick={() => handleClose()} marginTop={['2rem', '1rem']} />

              <Flex flexDirection="column" gap={['1rem', 'inherit']} bg={['gray.50', '#FFFFFF']}>
                {/* Heading */}
                <Flex
                  flexDirection="column"
                  bg="#E8EAF1"
                  padding={['2rem', '2rem 3rem 1.5rem 3rem']}
                  borderBottom={['inherit', '1px solid #E8EAF1']}
                >
                  <Text fontWeight="600" fontSize="1.5rem">
                    Shift Templates
                  </Text>
                  <Text fontWeight="400" width={['16rem', '28rem']}>
                    {shiftTemplate || updateIsLoading
                      ? 'View, edit, or delete your shift templates.'
                      : 'Create a new shift template.'}
                  </Text>
                </Flex>
                {/* Template Name */}
                <Flex justifyContent="space-between">
                  <Flex
                    flexDirection="column"
                    gap=".5rem"
                    bg="#FFFFFF"
                    padding={['2rem', '2rem 0rem']}
                    marginTop={['1.5rem', 'inherit']}
                    margin={['inherit', '0rem 3rem']}
                    borderBottom={['inherit', '1px solid #E8EAF1']}
                  >
                    <Text fontWeight="600">Shift template name</Text>
                    <Input
                      {...register('templateName')}
                      isDisabled={!!shiftTemplate && !isEditable}
                      placeholder="Template name"
                      _disabled={{opacity: 0.6}}
                      w={['inherit', '26rem']}
                    />
                    {errors?.templateName && (
                      <Text color="red.500" fontSize="sm">
                        {errors?.templateName?.message}
                      </Text>
                    )}
                    <Text color="#718096">Give your saved shift a name</Text>
                  </Flex>

                  {(isEditable || shiftTemplate?.id) && isInternalAdmin && (
                    <Flex
                      padding={['2rem', '2rem']}
                      gap="0.3rem"
                      cursor="pointer"
                      onClick={e => {
                        e.stopPropagation();
                        onHistoryDrawerOpen();
                      }}
                    >
                      <Icon as={MdOutlineHistory} boxSize="18px" color="#313871" marginTop="0.1rem" />

                      <Text color="#313871" fontWeight="600" fontSize="14px" marginBottom=".2rem" maxW="12rem">
                        View History
                      </Text>
                    </Flex>
                  )}
                </Flex>

                <Flex
                  flexDirection={['column', 'row']}
                  gap=".5rem"
                  bg="#FFFFFF"
                  padding={['2rem', '2rem 0rem']}
                  margin={['inherit', '0rem 3rem']}
                  justifyContent={['inherit', 'space-between']}
                  borderBottom={['inherit', '1px solid #E8EAF1']}
                >
                  <UnitSelector
                    register={register}
                    control={control}
                    shiftTemplate={shiftTemplate}
                    isEditable={isEditable}
                    handleUnitSelection={handleUnitSelection}
                    errors={errors}
                    unitOptions={unitOptions}
                  />
                  <Flex flexDirection="column" gap=".5rem">
                    <Flex justifyContent={allowMultiPositions ? 'space-around' : 'inherit'} alignItems="center">
                      <Text fontWeight="600">Position</Text>
                      {allowMultiPositions && (
                        <Flex alignItems="center" display={selectedUnitPositions ? 'inherit' : 'none'}>
                          <FormLabel fontWeight="400" fontSize="14px" htmlFor="multi-position" mb="0">
                            Allow multiple positions
                          </FormLabel>
                          <Flex alignItems="center" gap=".5rem">
                            <Switch
                              id="multi-position"
                              onChange={() => setIsMulti(!isMulti)}
                              disabled={
                                !!(shiftTemplate && !isEditable) ||
                                (!shiftTemplate && getValues('position')?.length > 1)
                              }
                              isChecked={isMulti}
                            />
                            <Tooltip label="Multiple position types can apply for this shift" placement="top">
                              <Flex>
                                <Icon as={LiaInfoCircleSolid} boxSize="1.2rem" />
                              </Flex>
                            </Tooltip>
                          </Flex>
                        </Flex>
                      )}
                    </Flex>
                    <PositionSelector
                      selectedUnitPositions={selectedUnitPositions}
                      shiftTemplate={shiftTemplate}
                      getValues={getValues}
                      isInCardData={isInCardData}
                      isEditable={isEditable}
                      errors={errors}
                      watch={watch}
                      setValue={setValue}
                      isMulti={isMulti}
                    />
                  </Flex>
                </Flex>
                {/* Rate */}
                <Flex
                  bg="#FFFFFF"
                  padding={['2rem', '2rem 0rem']}
                  flexDirection="column"
                  gap={['.5rem', '2rem']}
                  margin={['inherit', '0rem 3rem']}
                  borderBottom={['inherit', '1px solid #E8EAF1']}
                  display={showRates ? 'inherit' : 'none'}
                >
                  <RateSelector
                    getValues={getValues}
                    setValue={setValue}
                    shiftTemplate={shiftTemplate}
                    isEditable={isEditable}
                    selectedPositionBaseRate={selectedPositionBaseRate}
                  />
                  {isRateOutsideOfRange && (
                    <Text color="red.500" fontSize="sm">
                      Rate is out of range.
                    </Text>
                  )}
                  {/* Bonus */}
                  <BonusSelector
                    shiftTemplate={shiftTemplate}
                    isEditable={isEditable}
                    hasBonus={hasBonus}
                    handleHasBonus={handleHasBonus}
                    register={register}
                    formatToDollarAmount={formatToDollarAmount}
                    defaultValues={defaultValues}
                    watch={watch}
                    handleBonusChange={handleBonusChange}
                    setValue={setValue}
                    setHasBonus={setHasBonus}
                  />
                </Flex>
                <Flex
                  flexDirection="column"
                  gap=".5rem"
                  bg="#FFFFFF"
                  padding={['2rem', '2rem 0rem']}
                  margin={['inherit', '0rem 3rem']}
                  borderBottom={['inherit', '1px solid #E8EAF1']}
                >
                  {/* Day */}
                  <DaySelector
                    days={days}
                    watch={watch}
                    handleDaySelection={handleDaySelection}
                    errors={errors}
                    shiftTemplate={shiftTemplate}
                    isEditable={isEditable}
                    openingButNoDay={openingButNoDay}
                    getValues={getValues}
                    setValue={setValue}
                  />
                  {openingButNoDay && (
                    <Text color="red.500" fontSize="sm">
                      Please select a day
                    </Text>
                  )}
                  <Flex flexDirection={['column', 'row']} gap={['.5rem', '2rem']} marginBottom={['inherit', '1.2rem']}>
                    <TimeSelector
                      register={register}
                      watch={watch}
                      errors={errors}
                      isEditable={isEditable}
                      shiftTemplate={shiftTemplate}
                      setValue={setValue}
                    />
                    {/* Break */}
                    <BreakSelector
                      breakDefaultValues={breakDefaultValues}
                      shiftTemplate={shiftTemplate}
                      isEditable={isEditable}
                      handleBreakSelection={handleBreakSelection}
                      setValue={setValue}
                      register={register}
                      currentBreakValues={currentBreakValues}
                    />
                  </Flex>
                  {totalShiftTime.asHours() > 13 && (
                    <Box>
                      <Alert
                        bg="#E8EAF1"
                        borderRadius="6px"
                        borderLeft="4px solid #38427E"
                        display="flex"
                        alignItems="flex-start"
                        justifyContent="space-between"
                        marginBottom="1.5rem"
                      >
                        <Flex>
                          <AlertIcon color="#38427E" marginRight="1rem" />
                          <Flex flexDirection="column">
                            <Flex flexDirection="column">
                              <AlertDescription>
                                This shift will be scheduled for a total duration of {longShiftAlert}.
                              </AlertDescription>
                            </Flex>
                          </Flex>
                        </Flex>
                      </Alert>
                    </Box>
                  )}
                  {/* Openings */}
                  <OpeningsSelector
                    shiftTemplate={shiftTemplate}
                    getValues={getValues}
                    getFullDayName={getFullDayName}
                    watch={watch}
                    isEditable={isEditable}
                    handleDecrementNumOfOpenings={() => handleDecrementNumOfOpenings(getValues, setValue, watch)}
                    handleIncrementNumOfOpenings={() => handleIncrementNumOfOpenings(setValue, watch)}
                    errors={errors}
                    handleDeleteDay={() => handleDeleteDay(setValue)}
                  />
                </Flex>
                {/* Qualifications */}
                <Flex
                  flexDirection={['column', 'row']}
                  gap={['.5rem', '1rem']}
                  bg="#FFFFFF"
                  padding={['2rem', '2rem 0rem']}
                  margin={['inherit', '0rem 3rem']}
                >
                  <QualificationsSelector
                    qualificationsDefaultValues={qualificationsDefaultValues}
                    groupedQualificationOptions={groupedQualificationOptions}
                    shiftTemplate={shiftTemplate}
                    isEditable={isEditable}
                    errors={errors}
                    register={register}
                    missingIds={missingIds}
                    currentQualifications={currentQualifications}
                    setValue={setValue}
                  />
                  {/* Description */}
                  <DescriptionInput
                    shiftTemplate={shiftTemplate}
                    isEditable={isEditable}
                    placeholder="You can enter shift descripton here"
                    register={register}
                    title="Shift Description"
                    subText="Provide specialty requirements, desired expertise, or instructions if any."
                    name="description"
                    defaultValue={
                      shiftTemplate
                        ? shiftTemplate.content.description
                        : firstPositionSelectedDescriptionAndInstructions?.description
                        ? firstPositionSelectedDescriptionAndInstructions?.description
                        : ''
                    }
                  />
                </Flex>
                {/* Check in Instructions */}
                <Flex
                  flexDirection={['column', 'row']}
                  gap={['.5rem', '1rem']}
                  bg="#FFFFFF"
                  padding={['2rem', '2rem 0rem']}
                  margin={['inherit', '0rem 3rem']}
                >
                  <DescriptionInput
                    shiftTemplate={shiftTemplate}
                    isEditable={isEditable}
                    register={register}
                    title="Check in instructions"
                    name="clockInInstruction"
                    subText="Provide instructions to clock in for the shift."
                    defaultValue={
                      shiftTemplate
                        ? shiftTemplate.content.clockInInstruction
                        : firstPositionSelectedDescriptionAndInstructions?.clockInInstruction
                        ? firstPositionSelectedDescriptionAndInstructions?.clockInInstruction
                        : ''
                    }
                    placeholder="You can enter check in instructions here"
                  />
                  {/* Check out Instructions */}
                  <DescriptionInput
                    shiftTemplate={shiftTemplate}
                    isEditable={isEditable}
                    register={register}
                    title="Check out instructions"
                    name="clockOutInstruction"
                    subText="Provide instructions to clock out for the shift."
                    defaultValue={
                      shiftTemplate
                        ? shiftTemplate.content.clockOutInstruction
                        : firstPositionSelectedDescriptionAndInstructions?.clockOutInstruction
                        ? firstPositionSelectedDescriptionAndInstructions?.clockOutInstruction
                        : ''
                    }
                    placeholder="You can enter check out instructions here"
                  />
                </Flex>
              </Flex>
            </Flex>
            <ShiftTemplateFooter
              shiftTemplate={shiftTemplate}
              isEditable={isEditable}
              setShowConfirmationModal={setShowConfirmationModal}
              showConfirmationModal={showConfirmationModal}
              handleDeleteShiftTemplate={handleDeleteShiftTemplate}
              handleClose={handleClose}
              handleEditClick={handleEditClick}
              formIsDirty={formIsDirty}
              isSubmitting={isSubmitting}
              // isRateOutsideOfRange={isRateOutsideOfRange}
              openingButNoDay={openingButNoDay}
            />
          </form>
        </ModalBody>
      </ModalContent>

      <ActivityHistoryDrawer
        isOpen={isHistoryDrawerOpen}
        onClose={onHistoryDrawerClose}
        resourceId={shiftTemplate?.id || ''}
        resourceType="SHIFT_TEMPLATE"
      />
    </ChakraModal>
  );
};

export default TemplateForm;
