import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import React, {FC, useEffect, useMemo, useState} from 'react';
import {FaAnglesRight} from 'react-icons/fa6';
import uuid from 'react-uuid';
import moment from 'moment/moment';
// import {ShiftsState} from 'shared/slices/calShiftSlice';
import {IoMdAddCircle} from 'react-icons/io';
import {useNavigate} from 'react-router-dom';
import {useTimeZone} from '../../CreateShift/appSelectors';
import ShiftUnitFilter from '../../CalendarListView/ShiftUnitFilter';
import useAppSelector from '../../../../shared/hooks/useAppSelector';
import {getFilteredSummary, renderShiftStatusText} from '../helpers/helpers';
import ShiftInformationDrawer from './ShiftInformationDrawer';
import {useLazyGetShiftDetails} from '../../../../shared/gql/CalendarShifts/queries';
import renderStatusIndicator from '../helpers/ShiftIndicator';
import {ReduxState} from '../../../../shared/types';
import FormatNdProName from '../helpers/FormatNdProName';
import {ShiftCard, UnitPosition} from '../../Shift.types';


interface IMonthlyCalendarAgendaDrawer {
  isOpen: boolean;
  onClose: () => void;
  selectedDate: string;
  shiftDetails: {
    shifts: ShiftCard[];
    counts: any;
    currentDate: Date;
    selectedDay: any;
    selectedTabFilter?: string;
    selectedFilterCount?: number;
  };
  searchAllShifts: () => Promise<void>;
  displayPostShift: boolean;
}

const tableCellStyles = {
  border: '1px solid #CBD5E0',
};

const MonthlyCalendarAgendaDrawer: FC<IMonthlyCalendarAgendaDrawer> = ({
  isOpen,
  onClose,
  selectedDate,
  shiftDetails,
  searchAllShifts,
  displayPostShift,
}) => {
  const timeZone = useTimeZone();
  const navigate = useNavigate();
  const [selectedUnitFilter, setSelectedUnitFilter] = useState<{unitId: string; positions: string[]}[]>([]);

  const handleSelectedUnitFilter = (unitIds: {unitId: string; positions: string[]}[]) => {
    setSelectedUnitFilter(unitIds);
  };

  const facilityUnits = useAppSelector(
    (state: ReduxState) => state.shiftTemplates.facilityUnits?.getFacilityUnitPositionRate.units
  );

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [sortedShiftsSummary, setSortedShiftsSummary] = useState<UnitPosition[]>([]);
  const {isOpen: isInfoDrawerOpen, onOpen: onInfoDrawerOpen, onClose: onInfoDrawerClose} = useDisclosure();
  const {getShiftDetails, data, loading} = useLazyGetShiftDetails();
  const [drawerData, setDrawerData] = useState<any>(null);

  const {
    shifts: selectedShifts,
    counts,
    currentDate,
    selectedDay,
    selectedTabFilter,
    selectedFilterCount,
  } = shiftDetails;

  const totalShiftOpeningsCount = selectedShifts
    ?.filter(
      innerShift =>
        selectedUnitFilter?.find(unit => unit?.unitId === innerShift.unitId) &&
        selectedUnitFilter
          ?.find(unit => unit.unitId === innerShift.unitId)
          ?.positions?.includes(innerShift?.positionDetail?.[0]?.id)
    )
    .reduce((acc, shift) => acc + Number(shift?.openings?.length), 0);

  const [selectedTab, setSelectedTab] = useState({value: '', count: totalShiftOpeningsCount});

  useEffect(() => {
    if (selectedTabFilter && isOpen) setSelectedTab({value: selectedTabFilter, count: selectedFilterCount || 0});
    else setSelectedTab({value: '', count: totalShiftOpeningsCount});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, totalShiftOpeningsCount]);

  const drawerRef = React.useRef(null);

  const checkStatus = (shift: any) => {
    // status : filled , unfilled , completed , pending , cancelled
    // This status is based on counts

    if (
      selectedTab?.value === 'unfilled' &&
      shift?.openings?.some((opening: {status: string}) =>
        ['Opened', 'Selected', 'OpenedWithApplicants'].includes(opening?.status)
      )
    ) {
      return true;
    }

    if (
      selectedTab?.value === 'pending' &&
      shift?.openings?.some((opening: {status: string}) => ['OpenedWithApplicants'].includes(opening.status))
    ) {
      return true;
    }

    if (
      selectedTab?.value === 'completed' &&
      shift?.openings?.some((opening: {status: string}) =>
        ['PendingReview', 'Completed', 'PendingClockIn', 'PendingCheckIn'].includes(opening.status)
      )
    ) {
      return true;
    }

    if (
      selectedTab?.value === 'cancelled' &&
      shift?.openings?.some((opening: {status: string}) => ['Cancelled'].includes(opening.status))
    ) {
      return true;
    }
    if (
      selectedTab?.value === 'filled' &&
      shift?.openings?.some((opening: {status: string}) =>
        ['Confirmed', 'Completed', 'PendingReview', 'PendingClockIn', 'PendingCheckIn'].includes(opening.status)
      )
    ) {
      return true;
    }
    if (selectedTab?.value === '') {
      return true;
    }

    return false;
  };

  const sortedShifts = useMemo(
    () =>
      selectedShifts
        ?.filter(
          innerShift =>
            selectedUnitFilter?.find(unit => unit?.unitId === innerShift.unitId) &&
            selectedUnitFilter
              ?.find(unit => unit.unitId === innerShift.unitId)
              ?.positions?.includes(innerShift?.positionDetail?.[0]?.id)
        )
        ?.filter(innerShift => checkStatus(innerShift))
        ?.sort((a, b) => Number(new Date(a.startTime).getTime()) - Number(new Date(b.startTime).getTime())),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedShifts, selectedUnitFilter, timeZone, selectedTab]
  );

  const filteredShifts = sortedShifts?.map(shift => {
    const ndPros = shift?.openings?.map(opening => opening?.ndProId);
    const filteredApplicants = shift?.applicants
      ?.filter((applicant, index, self) => self.findIndex(t => t.ndProId === applicant.ndProId) === index)
      ?.filter(applicant => !ndPros?.includes(applicant?.ndProId));

    const openedOpenings = shift?.openings
      ? shift.openings.filter(opening => ['Opened', 'OpenedWithApplicants'].includes(opening.status))
      : [];

    const updatedOpenings = openedOpenings.map((opening, index) => {
      if (filteredApplicants?.length > index) {
        return {
          ...opening,
          status: 'OpenedWithApplicants',
        };
      }

      return {
        ...opening,
        status: 'Opened',
      };
    });

    const otherOpenings = shift?.openings
      ? shift.openings.filter(opening => !['Opened', 'OpenedWithApplicants'].includes(opening.status))
      : [];

    const filteredOpening = [...otherOpenings, ...updatedOpenings];

    return {...shift, openings: filteredOpening};
  });

  useEffect(() => {
    if (facilityUnits) {
      const sorted = [...facilityUnits].sort((a, b) => a.name.localeCompare(b.name));
      setSortedShiftsSummary(sorted);
      if (!selectedUnitFilter?.length) {
        setSelectedUnitFilter(
          sorted?.map(unit => ({
            unitId: unit.id,
            positions: unit.positions.map(position => position.id),
          }))
        );
      }
      setIsFirstRender(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnitFilter?.length, facilityUnits]);

  useEffect(() => {
    if (isFirstRender && sortedShiftsSummary.length > 0) {
      const firstUnit = sortedShiftsSummary[0];
      setSelectedUnitFilter([{unitId: firstUnit?.id, positions: firstUnit?.positions?.map(e => e?.id)}]);
      setIsFirstRender(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstRender, sortedShiftsSummary]);

  // render counts

  const today = new Date();
  const year = currentDate?.getFullYear();
  const month = currentDate?.getMonth();
  const currentDay = today.getDate();
  const isCurrentMonth = today.getMonth() === month && today.getFullYear() === year;
  const isNextMonths = year > today.getFullYear() || (year === today.getFullYear() && month > today.getMonth());

  const onTabClick = (status: string, count: number) => {
    if (selectedTab?.value === '' || selectedTab?.value !== status) {
      setSelectedTab({value: status, count});
    } else {
      setSelectedTab({value: '', count: totalShiftOpeningsCount});
    }
  };

  const filteredSummary = getFilteredSummary([{shifts : selectedShifts}], sortedShiftsSummary)

  

  const renderCounts = () => {
    if (
      (isNextMonths || (isCurrentMonth && Number(selectedDay) >= currentDay)) &&
      (counts?.unfilledCount || counts?.pendingCount || counts?.filledCount || counts?.cancelledCount)
    ) {
      return (
        <Flex justifyContent="space-between" gap={2}>
          <Flex alignItems="center" justifyContent="flex-start" padding="8px" gap={2}>
            {counts.filledCount > 0 && (
              <Box
                padding="0.17rem 0.5rem"
                backgroundColor="#5AC68D4D"
                color="#0000007A"
                width="146px"
                borderRadius={4}
                border={selectedTab?.value === 'filled' ? '2px solid #5AC68D' : ''}
                onClick={() => onTabClick('filled', counts.filledCount)}
                cursor="pointer"
              >
                Filled -{' '}
                <Text as="span" color="#000000CC" fontWeight={600}>
                  {counts.filledCount}
                </Text>
              </Box>
            )}
            {counts.unfilledCount > 0 && (
              <Box
                padding="0.17rem 0.5rem"
                backgroundColor="#f1ddcc"
                color="#0000007A"
                width="146px"
                borderRadius={4}
                border={selectedTab?.value === 'unfilled' ? '2px solid #ED8936' : ''}
                onClick={() => onTabClick('unfilled', counts.unfilledCount)}
                cursor="pointer"
              >
                Unfilled -{' '}
                <Text as="span" color="#000000CC" fontWeight={600}>
                  {counts.unfilledCount}
                </Text>
              </Box>
            )}
            {counts.pendingCount > 0 && (
              <Box
                padding="0.17rem 0.5rem"
                backgroundColor="#2970FF1A"
                color="#0000007A"
                width="146px"
                borderRadius={4}
                border={selectedTab?.value === 'pending' ? '2px solid #313871' : ''}
                onClick={() => onTabClick('pending', counts.pendingCount)}
                cursor="pointer"
              >
                Pending -{' '}
                <Text as="span" color="#000000CC" fontWeight={600}>
                  {counts.pendingCount}
                </Text>
              </Box>
            )}
            {counts.cancelledCount > 0 && (
              <Box
                padding="0.17rem 0.5rem"
                backgroundColor="#D9D9D980"
                color="#0000007A"
                width="146px"
                borderRadius={4}
                border={selectedTab?.value === 'cancelled' ? '2px solid #D9D9D9' : ''}
                onClick={() => onTabClick('cancelled', counts.cancelledCount)}
                cursor="pointer"
              >
                Cancelled -{' '}
                <Text as="span" color="#000000CC" fontWeight={600}>
                  {counts.cancelledCount}
                </Text>
              </Box>
            )}
          </Flex>

          <Text color="#0000007A" fontWeight={600} padding="8px">
            Showing {selectedTab?.count} of {totalShiftOpeningsCount} total opening
          </Text>
        </Flex>
      );
    }
    if (counts?.completedCount || counts?.unfilledCount || counts?.cancelledCount)
      return (
        <Flex justifyContent="space-between" gap={2}>
          <Flex alignItems="center" justifyContent="flex-start" padding="8px" gap={2}>
            {counts.completedCount > 0 && (
              <Box
                padding="0.17rem 0.5rem"
                backgroundColor="#5AC68D4D"
                color="#0000007A"
                width="146px"
                borderRadius={4}
                border={selectedTab?.value === 'completed' ? '2px solid #5AC68D' : ''}
                onClick={() => onTabClick('completed', counts.completedCount)}
                cursor="pointer"
              >
                Completed -{' '}
                <Text as="span" color="#000000CC" fontWeight={600}>
                  {counts.completedCount}
                </Text>
              </Box>
            )}

            {counts.unfilledCount > 0 && (
              <Box
                padding="0.17rem 0.5rem"
                backgroundColor="#f1ddcc"
                color="#0000007A"
                width="146px"
                borderRadius={4}
                border={selectedTab?.value === 'unfilled' ? '2px solid #ED8936' : ''}
                onClick={() => onTabClick('unfilled', counts.unfilledCount)}
                cursor="pointer"
              >
                Unfilled -{' '}
                <Text as="span" color="#000000CC" fontWeight={600}>
                  {counts.unfilledCount}
                </Text>
              </Box>
            )}
            {counts.cancelledCount > 0 && (
              <Box
                padding="0.17rem 0.5rem"
                backgroundColor="#D9D9D980"
                color="#0000007A"
                width="146px"
                borderRadius={4}
                border={selectedTab?.value === 'cancelled' ? '2px solid #D9D9D9' : ''}
                onClick={() => onTabClick('cancelled', counts.cancelledCount)}
                cursor="pointer"
              >
                Cancelled -{' '}
                <Text as="span" color="#000000CC" fontWeight={600}>
                  {counts.cancelledCount}
                </Text>
              </Box>
            )}
          </Flex>

          <Text color="#0000007A" fontWeight={600} padding="8px">
            Showing {selectedTab?.count} of {totalShiftOpeningsCount} total opening
          </Text>
        </Flex>
      );

    return (
      <Flex alignItems="center" justifyContent="flex-end" padding="8px" gap={2}>
        <Text color="#0000007A" fontWeight={600}>
          Showing {selectedTab?.count} of {totalShiftOpeningsCount} total opening
        </Text>
      </Flex>
    );
  };

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
        <DrawerOverlay style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}} />
        <DrawerContent overflow="auto">
          <DrawerHeader background="#F9FAFB">
            <Flex alignItems="center" justifyContent="space-between">
              <Flex flexDir="row" alignItems="center" className="header-container">
                <Box ml="-10px" cursor="pointer" onClick={onClose}>
                  <FaAnglesRight size="14px" />
                </Box>
                <Text fontSize="1rem" fontWeight="600" pl="10px">
                  Agenda View
                </Text>
              </Flex>
              {displayPostShift && (
                <Button
                  background="#313871"
                  color="white"
                  _hover={{background: 'white'}}
                  onClick={() => navigate('/shifts/create')}
                  height="fit-content"
                  minH="2.125rem"
                >
                  <Flex gap="0.5rem" alignItems="center">
                    <IoMdAddCircle /> <Text>Post Shift</Text>
                  </Flex>
                </Button>
              )}
            </Flex>
          </DrawerHeader>
          <DrawerBody padding={0}>
            <Flex direction="column">
              <Flex alignItems="center" justifyContent="center" padding="0.782rem" position="relative">
                <Text fontWeight="600">{selectedDate}</Text>
                <Flex gap="0.5rem" position="absolute" right={5} alignItems="center">
                  {filteredSummary && (filteredSummary?.[0]?.positions?.length > 1 || filteredSummary?.length > 1) && (
                    <ShiftUnitFilter
                      shiftsSummary={filteredSummary || []}
                      filteredShiftCount={filteredShifts?.length}
                      totalShiftCount={selectedShifts?.length}
                      selectedUnitFilter={selectedUnitFilter}
                      handleSelectedUnitFilter={handleSelectedUnitFilter}
                      shifts={selectedShifts}
                      isFromMonthlyDrawer
                    />
                  )}
                </Flex>
              </Flex>
              <Divider />

              {renderCounts()}

              <Divider />
              <Table variant="simple" colorScheme="whiteAlpha">
                <Thead background="#0000000A">
                  <Tr>
                    <Th
                      textAlign="start"
                      sx={tableCellStyles}
                      fontSize="0.75rem"
                      fontWeight="500"
                      textTransform="capitalize"
                    >
                      Time
                    </Th>
                    <Th
                      textAlign="start"
                      sx={tableCellStyles}
                      fontSize="0.75rem"
                      fontWeight="500"
                      textTransform="capitalize"
                    >
                      Shift Type
                    </Th>
                    <Th
                      textAlign="start"
                      sx={tableCellStyles}
                      fontSize="0.75rem"
                      fontWeight="500"
                      textTransform="capitalize"
                    >
                      Status
                    </Th>
                    <Th
                      textAlign="start"
                      sx={tableCellStyles}
                      fontSize="0.75rem"
                      fontWeight="500"
                      textTransform="capitalize"
                    >
                      Nurses
                    </Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {filteredShifts?.map(shift => {
                    const unitDetails = facilityUnits?.find(unit => unit?.id === shift?.unitId);

                    const details = {
                      startDate: moment(shift.startTime).startOf('week'),
                      endDate: moment(shift.startTime).endOf('week'),
                      unitName: facilityUnits?.find(summary => summary?.id === shift.unitId)?.name,
                      position: shift.position,
                    };

                    const shiftStatus = renderShiftStatusText(shift);

                    return (
                      <Tr
                        key={uuid()}
                        background="white"
                        onClick={() => {
                          getShiftDetails({
                            variables: {
                              shiftId: shift?.shiftId,
                            },
                          });
                          onInfoDrawerOpen();
                          setDrawerData(details);
                        }}
                        _hover={{cursor: 'pointer', background: '#F9FAFB'}}
                      >
                        <Td textAlign="start" sx={tableCellStyles} fontSize="0.875rem" fontWeight="600">
                          {moment(shift.startTime).format('h:mm A')} - {moment(shift.endTime).format('h:mm A')}
                        </Td>
                        <Td textAlign="start" sx={tableCellStyles} fontSize="0.875rem" fontWeight="600">
                          {unitDetails?.name || ''} / {shift?.position}
                        </Td>
                        <Td textAlign="start" sx={tableCellStyles} fontSize="0.875rem" fontWeight="600">
                          {shiftStatus?.status ? (
                            <Flex flexDirection="column">
                              <Flex alignItems="center" gap="10px">
                                {renderStatusIndicator(shift, timeZone)} {shiftStatus?.status}
                              </Flex>
                              <Text fontWeight="500" fontSize="0.65rem" paddingLeft="24px">
                                {shiftStatus?.subStatus}
                              </Text>
                            </Flex>
                          ) : null}
                        </Td>
                        <Td textAlign="start" sx={tableCellStyles} fontSize="0.875rem" fontWeight="600">
                          {FormatNdProName(shift)}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {data?.getShiftDetail && (
        <ShiftInformationDrawer
          isOpen={isInfoDrawerOpen}
          onClose={onInfoDrawerClose}
          finalFocusRef={drawerRef}
          shift={data.getShiftDetail}
          position={drawerData?.position}
          unitName={drawerData?.unitName}
          startDate={drawerData?.startDate}
          endDate={drawerData?.endDate}
          searchAllShifts={searchAllShifts}
          getShiftDetails={getShiftDetails}
          isLoading={loading}
        />
      )}
    </>
  );
};
export default MonthlyCalendarAgendaDrawer;
