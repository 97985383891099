import React from 'react';
import {useSearchParams} from 'react-router-dom';
import CalendarPage from './CalendarPage';
import MonthlyAgendaView from './MonthlyAgendaView';

const CalenderPageView = () => {
  const [searchParams] = useSearchParams();
  const view = searchParams.get('view');
  if (view === 'monthly-overview' || view === 'monthly-confirmed') return <MonthlyAgendaView />;
  return <CalendarPage />;
};

export default CalenderPageView;
