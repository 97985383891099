import React, {useEffect, useRef, useState} from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  Heading,
  Text,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  useDisclosure,
  Divider,
} from '@chakra-ui/react';
// import {FaStar} from 'react-icons/fa';

import moment from 'moment';
import uuid from 'react-uuid';

import {OpeningsV2} from 'shared/types';

import {useLazyGetShiftDetails} from '../../gql/CalendarShifts/queries';
import useAppSelector from '../../hooks/useAppSelector';
import {MapShift, ShiftCard} from '../../../routes/Shifts/Shift.types';

import NdProListItem from '../../../routes/Shifts/NdPro/NdProListItem';
import ShiftInformationDrawer from '../../../routes/Shifts/CalendarPage/ShiftInformationDrawer/ShiftInformationDrawer';
import './CalendarShiftCard.scss';
import {
  openingStatusOrder,
  OpeningStatusOrderType,
} from '../../../routes/Shifts/CalendarPage/ShiftInformationDrawer/constants';
import {useTimeZone} from '../../../routes/Shifts/CreateShift/appSelectors';

type Props = {
  shift: ShiftCard;
  unitName: string;
  startDate: Date;
  endDate: Date;
  searchAllShifts: () => Promise<void>;
  handleRateNDProClick: (id: number, shiftData: any) => void;
};

const getBackgroundColor = (shift: ShiftCard) => {
  const hasOpenedWithApplicants = shift.openings.some(opening =>
    ['OpenedWithApplicants', 'Opened'].includes(opening.status)
  );

  if (hasOpenedWithApplicants) {
    return 'white';
  }

  const allCancelled = shift.openings.every(opening => opening.status === 'Cancelled');

  // If there are any openings with 'Opened' status, set the background to white.
  if (allCancelled) {
    return 'white';
  }

  // If no openings are 'PendingCheckIn', 'PendingClockIn', or 'Opened', then check for other special statuses.
  const hasSpecialStatus = [...shift.openings]
    .filter(opening => opening.status !== 'Cancelled')
    .every(opening =>
      ['Completed', 'Confirmed', 'PendingReview', 'PendingCheckIn', 'PendingClockIn'].includes(opening.status)
    );

  return hasSpecialStatus ? '#DFF4EA' : 'white';
};

const getBorderColor = (shift: MapShift, timeZone: string) => {
  const hasOpenedWithApplicants = shift.openings.some(opening => opening.status === 'OpenedWithApplicants');

  const pastEndTime = moment().isAfter(moment.tz(shift.endTime, timeZone));

  // Check if the same ndPro has applied to multiple openings and one is 'Selected'
  // const ndProApplications = shift.openings.reduce((acc: Record<string, number>, opening) => {
  //   if (opening.ndProName) {
  //     acc[opening.ndProName] = (acc[opening.ndProName] || 0) + 1;
  //   }
  //   return acc;
  // }, {});

  // const hasMultipleApplicationsWithSelected =
  //   Object.values(ndProApplications).some(count => count > 1) &&
  //   shift.openings.some(opening => opening.status === 'Selected');

  // if (hasMultipleApplicationsWithSelected) {
  //   return '#A3A3A3'; // Grey color for this specific case
  // }

  const hasCompleted = shift.openings.some(opening =>
    ['Completed', 'PendingReview', 'PendingClockIn', 'PendingCheckIn'].includes(opening.status)
  );

  if (hasCompleted) {
    return '#5AC68D'; // Green color
  }

  if (!pastEndTime && hasOpenedWithApplicants) {
    return '#667FF5'; // Blue color
  }

  const hasConfirmed = shift.openings.some(opening => ['Confirmed'].includes(opening.status));

  if (hasConfirmed) {
    return '#5AC68D'; // Green color
  }

  const allCancelled = shift.openings.every(opening => opening.status === 'Cancelled');

  if (allCancelled) {
    return 'rgba(163, 163, 163, 0.5)'; // Light grey color
  }

  return '#f1ddcc'; // Default grey color
};

const getTextColor = (shift: MapShift) => {
  const hasCompleted = shift.openings.some(opening =>
    ['Completed', 'Confirmed', 'PendingClockIn', 'PendingCheckIn'].includes(opening.status)
  );

  if (hasCompleted) {
    return '#31B870';
  }

  const hasCancelled = shift.openings.every((opening: OpeningsV2) => ['Cancelled'].includes(opening.status));

  if (hasCancelled) {
    return 'black';
  }

  const allOpenedWithApplicants = shift.openings.every(opening => opening.status === 'OpenedWithApplicants');

  if (allOpenedWithApplicants) {
    return 'black';
  }

  const hasPendingOrOpenOrSelected = shift.openings.some(opening =>
    ['Opened', 'Selected', 'OpenedWithApplicants'].includes(opening.status)
  );

  return hasPendingOrOpenOrSelected ? 'black' : '#47bf80';
};

// TO-DO: The commented code may be reimplemented once Shane tests
const CalendarShiftCard = ({
  shift: mainShiftData,
  unitName,
  startDate,
  endDate,
  searchAllShifts,
  handleRateNDProClick,
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [convStart, setConvStart] = useState('');
  const [convEnd, setConvEnd] = useState('');

  const ndPros = mainShiftData?.openings?.map(opening => opening?.ndProId);
  const filteredApplicants = mainShiftData?.applicants
    ?.filter((applicant, index, self) => self.findIndex(t => t.ndProId === applicant.ndProId) === index)
    ?.filter(applicant => !ndPros?.includes(applicant?.ndProId));

  const openedOpenings = mainShiftData?.openings
    ? mainShiftData.openings.filter(opening => ['Opened', 'OpenedWithApplicants'].includes(opening.status))
    : [];

  const updatedOpenings = openedOpenings.map((opening, index) => {
    if (filteredApplicants?.length > index) {
      return {
        ...opening,
        status: 'OpenedWithApplicants',
      };
    }

    return {
      ...opening,
      status: 'Opened',
    };
  });

  const otherOpenings = mainShiftData?.openings
    ? mainShiftData.openings.filter(opening => !['Opened', 'OpenedWithApplicants'].includes(opening.status))
    : [];

  const filteredOpening = [...otherOpenings, ...updatedOpenings];

  const shift = {...mainShiftData, openings: filteredOpening};

  const editedShiftId = useAppSelector(state => state.shifts.editedShift?.updateShiftOpening.shiftId);

  const {getShiftDetails, data, loading} = useLazyGetShiftDetails();

  const currentDate = moment.utc().format('YYYY-MM-DD');
  const shiftDate = moment.utc(shift.startTime).format('YYYY-MM-DD');
  const isCurrentDate = currentDate === shiftDate;

  const textColor = getTextColor(shift);

  const {isOpen: isInfoDrawerOpen, onOpen: onInfoDrawerOpen, onClose: onInfoDrawerClose} = useDisclosure();

  const drawerRef = React.useRef(null);

  const timeZone = useTimeZone();

  useEffect(() => {
    setConvStart(moment(shift.startTime).format('h:mm A'));
    setConvEnd(moment(shift.endTime).format('h:mm A'));
  }, [shift?.startTime, shift?.endTime]);

  useEffect(() => {
    if (convStart && convEnd) {
      setIsLoading(false);
    }
    if (editedShiftId) {
      getShiftDetails({
        variables: {
          shiftId: editedShiftId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedShiftId, convStart, convEnd]);

  useEffect(() => {
    if (data?.getShiftDetail) {
      setIsLoading(false);
    }
  }, [data]);

  const cardRef = useRef(null);

  const isPastShift = moment().isAfter(moment.tz(mainShiftData?.endTime, timeZone || 'UTC').add(5, 'minute'));

  const hasIndicatorStatus =
    !isPastShift && shift.openings.some(opening => ['OpenedWithApplicants'].includes(opening.status));

  const {shiftId} = shift;

  const sortedShiftOpenings = shift.openings
    .filter(opening => {
      // For past shifts, only include openings with 'PendingReview' , 'Completed' , 'PendingClockIn' or 'PendingCheckIn
      if (isPastShift) {
        return ['PendingReview', 'Completed', 'PendingClockIn', 'PendingCheckIn'].includes(opening.status);
      }
      // For current and future shifts, include all except 'Cancelled'
      return opening.status !== 'Cancelled';
    })
    .sort(
      (a, b) =>
        openingStatusOrder[a.status as keyof OpeningStatusOrderType] -
        openingStatusOrder[b.status as keyof OpeningStatusOrderType]
    );

  const renderStatusText = () => {
    const isAllCancelled = shift.openings.every(opening => opening.status === 'Cancelled');
    const completedCount = shift.openings.filter(opening => opening.status === 'Completed').length;
    const pendingReviewCount = shift.openings.filter(opening => opening.status === 'PendingReview').length;
    const allOpenedWithApplicants = shift.openings.every(opening => opening.status === 'OpenedWithApplicants');

    // Filter out cancelled openings before counting filled and total openings
    const nonCancelledOpenings = shift.openings.filter(opening => opening.status !== 'Cancelled');

    const filledCount = nonCancelledOpenings.filter(opening =>
      ['Confirmed', 'Completed', 'PendingCheckIn', 'PendingClockIn'].includes(opening.status)
    ).length;

    const totalOpenings = nonCancelledOpenings.length; // Total openings excluding cancelled ones

    // const fontWeight = filledCount > 0 ? '750' : '500';

    if (isAllCancelled) {
      return (
        <Text fontSize="0.75rem" color={textColor} fontWeight="500" style={{opacity: 0.4}}>
          Cancelled
        </Text>
      );
    }

    if (pendingReviewCount > 0) {
      return (
        <Flex alignItems="center">
          <Text
            fontSize="0.75rem"
            color="#405FF2"
            fontWeight="600"
            textDecoration="underline"
            cursor="pointer"
            onClick={e => {
              e?.stopPropagation();
              handleRateNDProClick(
                Number(sortedShiftOpenings?.find(sortedShift => sortedShift?.status === 'PendingReview')?.refShiftId),
                shift
              );
            }}
          >
            Rate {pendingReviewCount} NDPro{pendingReviewCount > 1 ? 's' : ''}
          </Text>
        </Flex>
      );
    }

    if (completedCount > 0) {
      return (
        <Text fontSize="0.75rem" color={textColor} fontWeight="700">
          {`${completedCount} Completed`}
        </Text>
      );
    }

    if (allOpenedWithApplicants) {
      return (
        <Text fontSize="0.75rem" color="black" fontWeight="500">
          {`0 of ${totalOpenings} Filled`}
        </Text>
      );
    }

    return (
      <Text fontSize="0.75rem" color={textColor} fontWeight={textColor === 'black' ? '500' : '700'}>
        {`${filledCount} of ${totalOpenings} Filled`}
      </Text>
    );
  };

  const allOpeningsAreOpenedOrWithApplicants = shift.openings.every(opening =>
    ['Opened', 'OpenedWithApplicants', 'Cancelled'].includes(opening.status)
  );

  const [isAccordionOpen, setIsAccordionOpen] = useState(
    sortedShiftOpenings?.every(opening => ['PendingReview', 'Completed', 'Confirmed'].includes(opening?.status))
  );

  const handleInnerClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleAccordionToggle = () => {
    setIsAccordionOpen(prevState => !prevState);
  };

  return (
    <Box
      borderWidth="0.09375rem"
      borderColor={getBorderColor(shift, timeZone || 'UTC')}
      borderTopWidth="0.375rem"
      borderRadius="0.375rem"
      position="relative"
      ref={cardRef}
      mb="0.3125rem"
    >
      <Card
        variant="elevated"
        className={`cal-shift-card ${hasIndicatorStatus ? 'has-status-indicator' : ''}`}
        onClick={() => {
          getShiftDetails({
            variables: {
              shiftId,
            },
          });
          onInfoDrawerOpen();
        }}
        bg={getBackgroundColor(shift)}
        boxShadow="0px 2px 6px rgba(0, 0, 0, 0.1)"
        width="9rem"
        height={isAccordionOpen ? 'auto' : 'fit-content'}
      >
        <CardHeader
          className="shift-card-header"
          style={{
            opacity:
              isPastShift &&
              !shift.openings.some(opening =>
                ['Completed', 'PendingReview', 'PendingClockIn', 'PendingCheckIn'].includes(opening.status)
              ) &&
              shift.openings.some(opening => opening.status !== 'Cancelled')
                ? 0.4
                : 1,
          }}
        >
          <Heading
            fontSize="0.8rem"
            fontWeight="600"
            style={{opacity: shift.openings.every(opening => opening.status === 'Cancelled') ? 0.4 : 1}}
          >
            {`${shift.position} / ${unitName}`}
          </Heading>

          <Box display="flex" justifyContent="left" width="10rem">
            <Text
              fontSize="0.75rem"
              fontWeight="400"
              style={{opacity: shift.openings.every(opening => opening.status === 'Cancelled') ? 0.4 : 1}}
            >
              {isLoading ? 'Loading...' : `${convStart} - ${convEnd}`}
            </Text>
          </Box>

          <Flex flexDir="row" justifyContent="flex-start" className="foottext" mt="0.625rem" width="100%">
            <Box
              mt="-2px"
              style={{
                opacity:
                  isPastShift &&
                  !shift.openings.some(opening =>
                    ['Completed', 'PendingReview', 'PendingClockIn', 'PendingCheckIn'].includes(opening.status)
                  ) &&
                  shift.openings.some(opening => opening.status !== 'Cancelled')
                    ? 0.4
                    : 1,
              }}
            >
              {renderStatusText()}
            </Box>
          </Flex>
        </CardHeader>

        {sortedShiftOpenings?.length > 0 && !allOpeningsAreOpenedOrWithApplicants && (
          <>
            <Divider borderColor="#00000014" width="100%" />

            <Accordion
              defaultIndex={
                (isCurrentDate && shift.openings && !shift.openings.some(opening => opening.ndProName === null)) ||
                sortedShiftOpenings?.every(opening => ['PendingReview', 'Completed'].includes(opening?.status))
                  ? [0]
                  : []
              }
              allowToggle
              // allowMultiple
            >
              {sortedShiftOpenings && (
                <AccordionItem border="none" borderRadius="0 0 0.375rem 0.375rem" background="white" width="100%">
                  <Flex display="flex" flexDirection="column" key={uuid()} pt="0.3125rem" width="100%">
                    {sortedShiftOpenings.every(opening =>
                      ['PendingReview', 'Completed', 'Confirmed'].includes(opening?.status)
                    ) ? (
                      sortedShiftOpenings.map(opening => (
                        <NdProListItem key={uuid()} opening={opening} isPastShift={isPastShift} />
                      ))
                    ) : (
                      <>
                        {sortedShiftOpenings.slice(0, 2).map(opening => (
                          <NdProListItem key={uuid()} opening={opening} isPastShift={isPastShift} />
                        ))}
                        {sortedShiftOpenings.length > 2 && (
                          <>
                            <AccordionPanel
                              display="flex"
                              flexDir="column"
                              width="100%"
                              alignItems="center"
                              padding="0"
                            >
                              <CardBody className="shift-card-body" flexDir="column" width="100%" padding="0">
                                <Flex display="flex" flexDirection="column" key={uuid()} width="100%">
                                  {sortedShiftOpenings.slice(2).map(opening => (
                                    <NdProListItem key={uuid()} opening={opening} isPastShift={isPastShift} />
                                  ))}
                                </Flex>
                              </CardBody>
                            </AccordionPanel>
                            <AccordionButton
                              width="100%"
                              background="#0000000F"
                              _hover={{background: '#0000000F'}}
                              padding={0}
                              onClick={e => {
                                handleInnerClick(e);
                                handleAccordionToggle();
                              }}
                            >
                              <Flex alignItems="center" justifyContent="center" width="100%">
                                <Text fontSize="0.7rem" color="#2D3748">
                                  {isAccordionOpen ? 'See less' : 'See more'}
                                </Text>
                                <AccordionIcon boxSize="4" />
                              </Flex>
                            </AccordionButton>
                          </>
                        )}
                      </>
                    )}
                  </Flex>
                </AccordionItem>
              )}
            </Accordion>
          </>
        )}

        <Box position="relative" zIndex={1}>
          {data?.getShiftDetail && (
            <ShiftInformationDrawer
              isOpen={isInfoDrawerOpen}
              onClose={onInfoDrawerClose}
              finalFocusRef={drawerRef}
              shift={data.getShiftDetail}
              position={shift.position}
              unitName={unitName}
              startDate={startDate}
              endDate={endDate}
              searchAllShifts={searchAllShifts}
              getShiftDetails={getShiftDetails}
              isLoading={isLoading || loading}
            />
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default CalendarShiftCard;
