import {Flex, Icon, Text} from '@chakra-ui/react';
import React from 'react';
import {FaHeart} from 'react-icons/fa';

const FormatNdProName = (shift: any) => {
  const formatName = (name: string, isFavorite: boolean) => (
    <Flex alignItems="center" gap="0.2rem" justifyContent="flex-start">
      <Text as="span" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" maxWidth='12rem'>
        {name} 
      </Text>{' '}
      {isFavorite && <Icon as={FaHeart} boxSize="14px" color="#FC8181" />}
    </Flex>
  );
  

  const ndProList = shift?.openings
    ?.filter((e: {status: string}) =>
      ['Confirmed', 'PendingReview', 'Completed', 'PendingCheckIn', 'PendingClockIn'].includes(e?.status)
    )
    .map((e: {ndProName: string; favorite: boolean}) => e?.ndProName && formatName(e?.ndProName, e?.favorite));

  return (
    <Flex flexDirection="column">
      {ndProList?.map((ndPro: string) => (
        <Text>{ndPro}</Text>
      ))}
    </Flex>
  );
};

export default FormatNdProName;
