import {ChevronDownIcon} from '@chakra-ui/icons';
import {Box, Button, Flex, Menu, MenuButton, MenuGroup, MenuItem, MenuList, Text} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {IoPrintOutline} from 'react-icons/io5';
import moment from 'moment';
import InfoIcon from './Icons/Info.svg';
import {Shift, UnitPosition} from '../Shift.types';
import PrintPreviewModal from './PrintPreviewModal';
import {useTimeZone} from '../CreateShift/appSelectors';
import {checkShiftStatus, getFilteredSummary} from './helpers/helpers';
import ShiftStatusFilter from '../CalendarListView/ShiftStatusFilter';
import ShiftUnitFilter from '../CalendarListView/ShiftUnitFilter';

type Props = {
  shiftsSummary: UnitPosition[];
  calenderComponent: JSX.Element;
  setSelectedUnitFilter: any;
  selectedUnitFilter: {unitId: string; positions: string[]}[];
  startDate: Date;
  endDate: Date;
  shifts: Shift[];
  statusFilter: string[];
  setStatusFilter: any;
  isMonthlyFilter?: boolean;
  isMonthlyConfirmedFilter?: boolean;
};

const CalenderFilters = ({
  shiftsSummary,
  selectedUnitFilter,
  startDate,
  endDate,
  shifts,
  setSelectedUnitFilter,
  setStatusFilter,
  statusFilter,
  isMonthlyFilter,
  isMonthlyConfirmedFilter,
  ...rest
}: Props) => {
  const navigate = useNavigate();
  const [view, setView] = useState('Weekly');
  const location = useLocation();
  const [isOpen, setIsOpen] = useState('');
  const [searchParams] = useSearchParams();
  const pageView = searchParams.get('view');

  useEffect(() => {
    // const currentView = location.pathname.includes('/monthly') ? 'Monthly' : 'Week';

    // setView(currentView);
    if (pageView === 'weekly') {
      setView('Weekly');
    }
    if (pageView === 'monthly-overview') {
      setView('Monthly Overview');
    }
    if (pageView === 'monthly-confirmed') {
      setView('Monthly Confirmed');
    }
  }, [location, pageView]);

  const legends = [
    {legend: 'Unfilled', background: '#f1ddcc', border: ''},
    {legend: 'Actions Required', background: '#2970FF', border: ''},
    {legend: 'Filled Completely', background: '#5AC68D', border: ''},
    {legend: 'Filled Partially', background: 'white', border: '3px solid #5AC68D'},
    {legend: 'Cancelled', background: '#D9D9D980', border: ''},
  ];

  const handleSelectedUnitFilter = (unitIds: React.SetStateAction<{unitId: string; positions: string[]}[]>) => {
    // setIsUserAction(true);
    setSelectedUnitFilter(unitIds);
  };

  // display print button only for admin
  const [PrintModalData, setPrintModalData] = useState<{
    isOpen: boolean;
  }>({isOpen: false});

  const datesOfWeek = Array.from({length: 7}, (_, i) => moment(startDate).add(i, 'days').format('YYYY-MM-DD'));

  const sessionUserTimeZone = useTimeZone();

  const weeklyShifts = [...shifts]?.filter(shift => datesOfWeek.includes(shift.date));

  const weeklyTotalShiftCount = [...weeklyShifts]
    ?.map(shift => ({
      ...shift,
      shifts: shift.shifts.filter(
        innerShift =>
          !['cancelled', 'pastUnfilled'].includes(checkShiftStatus(innerShift, sessionUserTimeZone || 'UTC'))
      ),
    }))
    ?.reduce((acc, curr) => acc + Number(curr?.shifts?.length), 0);

  const weeklyFilteredShiftCount = [...shifts]
    ?.filter(shift => datesOfWeek.includes(shift.date))
    ?.map(shift => ({
      ...shift,
      shifts: shift.shifts
        .filter(
          innerShift =>
            selectedUnitFilter.find(unit => unit.unitId === innerShift.unitId) &&
            selectedUnitFilter
              ?.find(unit => unit.unitId === innerShift.unitId)
              ?.positions?.includes(innerShift?.positionDetail?.[0]?.id)
        )
        .filter(innerShift => statusFilter.includes(checkShiftStatus(innerShift, sessionUserTimeZone || 'UTC'))),
    }))
    ?.reduce((acc, curr) => acc + Number(curr?.shifts?.length), 0);

  const monthlyShifts = [...shifts]?.filter(
    shift => new Date(shift?.date).getMonth() === new Date(startDate).getMonth()
  );

  const monthlyTotalShiftCount = monthlyShifts?.reduce((acc, curr) => acc + Number(curr?.shifts?.length), 0);

  const monthlyFilteredShiftCount = [...shifts]
    ?.filter(shift => new Date(shift?.date).getMonth() === new Date(startDate).getMonth())
    ?.map(shift => ({
      ...shift,
      shifts: shift.shifts
        .filter(
          innerShift =>
            selectedUnitFilter.find(unit => unit.unitId === innerShift.unitId) &&
            selectedUnitFilter
              ?.find(unit => unit.unitId === innerShift.unitId)
              ?.positions?.includes(innerShift?.positionDetail?.[0]?.id)
        )
        .filter(innerShift => statusFilter.includes(checkShiftStatus(innerShift, sessionUserTimeZone || 'UTC'))),
    }))
    ?.reduce((acc, curr) => acc + Number(curr?.shifts?.length), 0);

  // shifts for count

  const weeklyShiftsForUnitFilter = [...weeklyShifts].map(shift => ({
    ...shift,
    shifts: shift.shifts.filter(innerShift =>
      statusFilter.includes(checkShiftStatus(innerShift, sessionUserTimeZone || 'UTC'))
    ),
  }));

  const monthlyShiftsForUnitFilter = [...monthlyShifts].map(shift => ({
    ...shift,
    shifts: shift.shifts.filter(innerShift =>
      statusFilter.includes(checkShiftStatus(innerShift, sessionUserTimeZone || 'UTC'))
    ),
  }));

  const monthlyShiftForStatusFilter = [...monthlyShifts]?.map(shift => ({
    ...shift,
    shifts: shift.shifts.filter(
      innerShift =>
        selectedUnitFilter.find(unit => unit.unitId === innerShift.unitId) &&
        selectedUnitFilter
          ?.find(unit => unit.unitId === innerShift.unitId)
          ?.positions?.includes(innerShift?.positionDetail?.[0]?.id)
    ),
  }));

  const weeklyShiftsForStatusFilter = [...weeklyShifts]?.map(shift => ({
    ...shift,
    shifts: shift.shifts.filter(
      innerShift =>
        selectedUnitFilter.find(unit => unit.unitId === innerShift.unitId) &&
        selectedUnitFilter
          ?.find(unit => unit.unitId === innerShift.unitId)
          ?.positions?.includes(innerShift?.positionDetail?.[0]?.id)
    ),
  }));

  const mappedShiftsSummary = [...shiftsSummary]?.map(unit => ({
    unitId: unit.id,
    positions: unit.positions.map(position => position.id),
  }));

  useEffect(() => {
    const sortUnit = (unit: {unitId: any; positions: any}) => ({
      unitId: unit.unitId,
      positions: [...unit.positions].sort((a, b) => a - b),
    });

    const sortedArr1 = mappedShiftsSummary.map(sortUnit).sort((a, b) => a.unitId.localeCompare(b.unitId));
    const sortedArr2 = selectedUnitFilter.map(sortUnit).sort((a, b) => a.unitId.localeCompare(b.unitId));

    if (JSON.stringify(sortedArr1) !== JSON.stringify(sortedArr2)) handleSelectedUnitFilter(mappedShiftsSummary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(mappedShiftsSummary), startDate]);

  return (
    <Flex
      className="month-range-container"
      justifyContent="space-between"
      gap="0.5rem"
      alignItems="center"
      pb="3"
      maxWidth={isMonthlyFilter ? '100%' : '68rem'}
      width="100%"
    >
      <Flex gap="0.5rem" alignItems="center">
        <Menu>
          <MenuButton
            px={2}
            py={1}
            transition="all 0.2s"
            borderRadius="md"
            borderWidth="0.0625rem"
            fontWeight="600"
            fontSize="0.9rem"
          >
            {view}
            <ChevronDownIcon ml="2" />
          </MenuButton>
          <MenuList minWidth="none">
            <MenuItem
              onClick={() => {
                setView('Monthly Overview');
                navigate('/calendar?view=monthly-overview');
              }}
              fontSize="0.8rem"
            >
              Monthly Overview
            </MenuItem>

            <MenuItem
              onClick={() => {
                setView('Monthly Confirmed');
                navigate('/calendar?view=monthly-confirmed');
              }}
              fontSize="0.8rem"
            >
              Monthly Confirmed
            </MenuItem>

            <MenuItem
              onClick={() => {
                setView('Weekly');
                navigate('/calendar?view=weekly');
              }}
              width="100%"
              fontSize="0.8rem"
            >
              Weekly
            </MenuItem>
          </MenuList>
        </Menu>

        {!isMonthlyFilter && (
          <Button
            padding="0"
            variant="ghost"
            onClick={() => {
              setPrintModalData({isOpen: true});
            }}
            _hover={{bg: 'transparent'}}
            height="min-content"
          >
            <Flex alignItems="center">
              <IoPrintOutline />
              Print
            </Flex>
          </Button>
        )}
      </Flex>

      <Flex>{rest.calenderComponent}</Flex>

      <Flex gap="0.5rem">
        {(shiftsSummary?.[0]?.positions?.length > 1 || shiftsSummary?.length > 1) && (
          <ShiftUnitFilter
            shiftsSummary={
              isMonthlyFilter
                ? getFilteredSummary(monthlyShifts, shiftsSummary)
                : getFilteredSummary(weeklyShifts, shiftsSummary)
            }
            filteredShiftCount={isMonthlyFilter ? monthlyFilteredShiftCount : weeklyFilteredShiftCount}
            totalShiftCount={isMonthlyFilter ? monthlyTotalShiftCount : weeklyTotalShiftCount}
            selectedUnitFilter={selectedUnitFilter}
            handleSelectedUnitFilter={handleSelectedUnitFilter}
            shifts={isMonthlyFilter ? monthlyShiftsForUnitFilter : weeklyShiftsForUnitFilter}
          />
        )}

        {!isMonthlyConfirmedFilter && (
          <ShiftStatusFilter
            filteredShiftCount={isMonthlyFilter ? monthlyFilteredShiftCount : weeklyFilteredShiftCount}
            totalShiftCount={isMonthlyFilter ? monthlyTotalShiftCount : weeklyTotalShiftCount}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            shifts={isMonthlyFilter ? monthlyShiftForStatusFilter : weeklyShiftsForStatusFilter}
            timeZone={sessionUserTimeZone}
          />
        )}

        <Menu autoSelect={false} isOpen={isOpen === 'legend'}>
          <MenuButton
            height="28px"
            width="28px"
            onMouseEnter={() => setIsOpen('legend')}
            onMouseLeave={() => setIsOpen('')}
          >
            <img src={InfoIcon} alt=" " />
          </MenuButton>
          <MenuList>
            <MenuGroup title="Legend">
              {legends?.map(legend => (
                <MenuItem _hover={{background: 'transparent'}}>
                  <Flex width="100%" alignItems="center" justifyContent="space-between">
                    <Text fontSize="0.8rem">{legend?.legend}</Text>
                    <Box
                      background={legend.background}
                      height="14px"
                      width="14px"
                      borderRadius="50%"
                      border={legend.border}
                    >
                      {' '}
                    </Box>
                  </Flex>
                </MenuItem>
              ))}
            </MenuGroup>
          </MenuList>
        </Menu>
      </Flex>
      {PrintModalData?.isOpen && (
        <PrintPreviewModal
          handleIsModalOpen={setPrintModalData}
          shifts={shifts?.filter(shift => datesOfWeek?.includes(shift?.date))}
          units={shiftsSummary}
          selectedUnitFilter={selectedUnitFilter}
          timeZone={sessionUserTimeZone || 'UTC'}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </Flex>
  );
};

export default CalenderFilters;
