import {createColumnHelper} from '@tanstack/react-table';
import React from 'react';
import moment from 'moment';
import {User} from '../../shared/gql/Users/types';
import useAppSelector from '../../shared/hooks/useAppSelector';

const columnHelper = createColumnHelper<User>();

const formatDate = (date?: string): string => {
  if (date) {
    const now = moment();
    const inputDate = moment(date);

    const diffInDays = now?.diff(inputDate, 'days');

    if (diffInDays > 7) {
      return inputDate?.format('M/D/YY h:mma'); //  "November 25, 2024 2:30 PM"
    }

    return inputDate.fromNow();
  }
  return 'N/A';
};

const staticColumns = [
  {
    id: 'userName',
    header: 'NAME',
    accessor: (row: User) => {
      if (row?.firstName || row?.lastName) return `${row.firstName || ''} ${row.lastName || ''}`;
      return 'N/A';
    },
  },
  {
    id: 'email',
    header: 'EMAIL',
    accessor: (row: User) => row.email || 'N/A',
  },
  {
    id: 'role',
    header: 'ROLE',
    accessor: (row: User) => row.role || 'N/A',
  },
];

const useGetColumns = () => {
  const isInternalAdmin = useAppSelector(
    state => state?.auth?.decodedToken?.['https://nursedash.com/custom']?.canAssumeIdentity
  );

  const columns = staticColumns.map(({id, header, accessor}) =>
    columnHelper.accessor(row => accessor(row), {
      id,
      header: () => header,
      cell: info => info.getValue(),
    })
  );

  if (isInternalAdmin) {
    columns.push(
      columnHelper.accessor('lastLogin', {
        cell: info => {
          const {lastLoginWeb, lastLoginMobile} = info.row.original;
          return lastLoginWeb || lastLoginMobile ? (
            <div>
              {lastLoginWeb && <div>Web: {formatDate(lastLoginWeb)}</div>}
              {lastLoginMobile && <div>Mobile: {formatDate(lastLoginMobile)}</div>}
            </div>
          ) : (
            'N/A'
          );
        },
        header: () => 'LAST LOGIN',
        id: 'lastLogin',
      }),
      columnHelper.accessor('lastActive', {
        cell: info => {
          const {lastActiveWeb, lastActiveMobile} = info.row.original;
          return lastActiveWeb || lastActiveMobile ? (
            <div>
              {lastActiveWeb && <div>Web: {formatDate(lastActiveWeb)}</div>}
              {lastActiveMobile && <div>Mobile: {formatDate(lastActiveMobile)}</div>}
            </div>
          ) : (
            'N/A'
          );
        },
        header: () => 'LAST ACTIVE',
        id: 'lastActive',
      }),
      columnHelper.accessor('deletedAt', {
        cell: info => formatDate(info.row.original.deletedAt),
        header: () => 'DELETED DATE',
        id: 'deletedAt',
      })
    );
  }

  return columns;
};

export default useGetColumns;
