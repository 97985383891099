import React, {useEffect} from 'react';
// form
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';

// chakra
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Box,
} from '@chakra-ui/react';

import useAppSelector from '../../../../shared/hooks/useAppSelector';
import {useChangePassword} from '../../../../shared/gql/Users/mutations';
import {UsersState} from '../../../../shared/slices/usersSlice';
import useFacilityIdFromToken from '../../utils/tokenUtils';

import PasswordInput from './PasswordInput/PasswordInput';

import {Props} from './ChangePassword.types';

const ChangePassword = ({
  title,
  body,
  primaryBtnText,
  secondaryBtnText,
  primaryBtnVariant,
  handleIsModalOpen,
  displayToast,
  refetchUsers,
}: Props) => {
  const schema = yup.object().shape({
    password: yup.string().required('Password is required'),
    confirmation: yup.string().oneOf([yup.ref('password')], 'Passwords must match'),
  });

  const selectedUser = useAppSelector((state: {usersData: UsersState}) => state.usersData.user);
  const facilityIdFromDecodedToken = useFacilityIdFromToken();

  const {
    register,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm({resolver: yupResolver(schema), mode: 'onSubmit'});

  const {isOpen, onOpen, onClose} = useDisclosure();

  const valuesIfSelectedUser = (field: string) => (selectedUser ? watch(field) : '');

  const [changePassword] = useChangePassword(
    {
      variables: {
        resetPassword: {
          userId: selectedUser?.userId ?? '',
          password: valuesIfSelectedUser('password'),
        },
      },
    },
    undefined,
    refetchUsers
  );

  // ToDo: Add a loading state. Will need the figma ui for this.

  const onSubmit = async (data: any) => {
    if (!facilityIdFromDecodedToken) {
      displayToast('Failed to fetch facility ID', 'error');
      return;
    }
    try {
      const response = await changePassword();

      if (response.data) {
        displayToast('Password Changed!', 'success');
        onClose();
        handleIsModalOpen(false);
      } else if (!response.data) {
        displayToast('Failed to change passowrd', 'error');
      }
    } catch (error) {
      console.log('onChangePasswordError: ', error);
    }
    console.log('data:', data);
  };

  useEffect(() => {
    onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpen]);
  const handleCloseModal = () => {
    onClose();
    handleIsModalOpen(false);
  };

  return (
    <ChakraModal isOpen={isOpen} onClose={handleCloseModal} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="indigo.900" fontWeight="700" w="98%">
          {title}
        </ModalHeader>
        <ModalCloseButton
          type="button"
          onClick={() => {
            handleCloseModal();
          }}
        />
        <ModalBody marginBottom="1rem">{body}</ModalBody>

        <ModalBody>
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <PasswordInput
                  register={register}
                  label="New Password"
                  placeholder="Enter password"
                  name="password"
                  errors={errors}
                />
                <PasswordInput
                  register={register}
                  label="Confirm New Password"
                  placeholder="Enter password confirmation"
                  name="confirmation"
                  errors={errors}
                />
                <ModalFooter paddingRight="0">
                  <Button
                    width="6.25rem"
                    variant="secondary"
                    mr={3}
                    onClick={() => {
                      handleCloseModal();
                    }}
                    type="button"
                  >
                    {secondaryBtnText}
                  </Button>
                  <Button width="6.25rem" type="submit" variant={primaryBtnVariant}>
                    {primaryBtnText}
                  </Button>
                </ModalFooter>
              </Box>
            </form>
          </Box>
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};

export default ChangePassword;
