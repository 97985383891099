const settingFields = `
  covidMedicalExemption
  covidReligiousExemption
  covidStatus
  orientationMaterials {
    uuid
    src
    data
    title
    pages {
      src
      page
    }
    preparing {
      status
    }
  }
  covidVaccineRequired
  allowSingleDoseVaccine
`;

export default settingFields;