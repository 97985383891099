import {
  Avatar,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import React, {useEffect, useRef} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {useReactToPrint} from 'react-to-print';
import {IoPrintOutline} from 'react-icons/io5';
import uuid from 'react-uuid';
import moment from 'moment';
import {Shift, UnitPosition} from '../Shift.types';
import NdLogo from '../../../assets/logo/nd-logo-with-name.svg';
import FacilitySelector from '../../../shared/components/Navigation/FacilitySelector/FacilitySelector';
import {renderShiftStatusText} from './helpers/helpers';
import renderStatusIndicator from './helpers/ShiftIndicator';
import FormatNdProName from './helpers/FormatNdProName';
import {trackCalenderWeeklyPrintPageView} from '../../../shared/mixpanel/page-views';
import {trackPrintWeekEvent} from '../../../shared/mixpanel/events/calender';

type Props = {
  handleIsModalOpen: (value: {isOpen: boolean}) => void;
  shifts: Shift[];
  units: UnitPosition[];
  selectedUnitFilter: {unitId: string; positions: string[]}[];
  timeZone: string | null;
  startDate: Date;
  endDate: Date;
};

const tableCellStyles = {
  border: '1px solid #EBEFF3',
};

const PrintPreviewModal = ({
  handleIsModalOpen,
  shifts,
  units,
  selectedUnitFilter,
  timeZone,
  startDate,
  endDate,
}: Props) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  const handleClose = () => {
    onClose();
    handleIsModalOpen({isOpen: false});
  };

  useEffect(() => {
    onOpen();
  }, [onOpen]);

  // Print
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    trackCalenderWeeklyPrintPageView();
  }, []);

  const filterCancelledShift = shifts
    ?.map(day => ({
      ...day,
      shifts: day?.shifts?.filter(
        shift => !shift?.openings?.every((opening: {status: string}) => opening.status === 'Cancelled')
      ),
    }))
    ?.filter(day => day?.shifts?.length > 0);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered size="6xl">
      <ModalOverlay />
      <ModalContent style={{maxWidth: 'calc(100% - 120px)', maxHeight: 'calc(100% - 140px)'}}>
        <ModalHeader display="flex" alignItems="center" gap="0.3125rem">
          Preview
        </ModalHeader>
        <ModalCloseButton size="sm" marginTop={['.2rem', '.6rem']} />

        <ModalBody height="100%" overflow="auto" padding="0">
          <div ref={componentRef}>
            <Flex alignItems="center" justifyContent="space-between" padding="1rem">
              <img src={NdLogo} alt="ndLogo" style={{maxHeight: '40px'}} />
              <Text color="#8A8989" fontWeight="600" pt="0.5rem" alignSelf="flex-end">
                {moment(startDate).format('dddd, MMM Do YYYY')} - {moment(endDate).format('dddd, MMM Do YYYY')}
              </Text>
              <Flex alignItems="center" gap="0.2rem">
                <Avatar
                  size="sm"
                  src="https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
                />
                <FacilitySelector isIcon={false} />
              </Flex>
            </Flex>

            <Table variant="simple" colorScheme="whiteAlpha">
              <Thead background="#F2F4FB">
                <Tr>
                  <Th
                    textAlign="start"
                    sx={tableCellStyles}
                    fontSize="0.75rem"
                    fontWeight="500"
                    textTransform="capitalize"
                  >
                    Date
                  </Th>
                  <Th
                    textAlign="start"
                    sx={tableCellStyles}
                    fontSize="0.75rem"
                    fontWeight="500"
                    textTransform="capitalize"
                  >
                    Shift Type
                  </Th>
                  <Th
                    textAlign="start"
                    sx={tableCellStyles}
                    fontSize="0.75rem"
                    fontWeight="500"
                    textTransform="capitalize"
                  >
                    Time
                  </Th>
                  <Th
                    textAlign="start"
                    sx={tableCellStyles}
                    fontSize="0.75rem"
                    fontWeight="500"
                    textTransform="capitalize"
                  >
                    Status
                  </Th>
                  <Th
                    textAlign="start"
                    sx={tableCellStyles}
                    fontSize="0.75rem"
                    fontWeight="500"
                    textTransform="capitalize"
                  >
                    Scheduled NDPros
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {filterCancelledShift?.map((day, i) => {
                  const shiftsList = day?.shifts?.filter(
                    shift =>
                      selectedUnitFilter.find(unit => unit.unitId === shift.unitId) &&
                      selectedUnitFilter
                        ?.find(unit => unit.unitId === shift.unitId)
                        ?.positions?.includes(shift?.positionDetail?.[0]?.id)
                  );
                  // ?.filter(
                  //   shift => !shift?.openings?.every((opening: {status: string}) => opening.status === 'Cancelled')
                  // );

                  return (
                    <React.Fragment key={day.date}>
                      {shiftsList?.map((shift, index) => {
                        const unitDetails = units?.find(unit => unit?.id === shift?.unitId);

                        const shiftStatus = renderShiftStatusText(shift);

                        return (
                          <Tr key={uuid()} background={i % 2 === 0 ? 'white' : '#F2F4FB'}>
                            {index === 0 && (
                              <Td
                                rowSpan={shiftsList.length}
                                textAlign="center"
                                sx={tableCellStyles}
                                fontSize="0.875rem"
                                fontWeight="600"
                              >
                                <Flex flexDirection="column" alignItems="center" justifyContent="center" gap="0.5rem">
                                  <Text>{moment(day.date).format('dddd')}</Text>
                                  <Text>{moment(day.date).format('MMM Do')}</Text>
                                </Flex>
                              </Td>
                            )}
                            <Td textAlign="start" sx={tableCellStyles} fontSize="0.875rem" fontWeight="600">
                              {unitDetails?.name || ''} / {shift?.position}
                            </Td>
                            <Td textAlign="start" sx={tableCellStyles} fontSize="0.875rem" fontWeight="600">
                              {moment
                                .utc(shift.startTime)
                                .tz(timeZone || 'UTC')
                                .format('h:mm A')}{' '}
                              -{' '}
                              {moment(shift.endTime)
                                .tz(timeZone || 'UTC')
                                .format('h:mm A')}
                            </Td>
                            <Td textAlign="start" sx={tableCellStyles} fontSize="0.875rem" fontWeight="600">
                              {shiftStatus?.status ? (
                                <Flex flexDirection="column">
                                  <Flex alignItems="center" gap="10px">
                                    {renderStatusIndicator(shift, timeZone || 'UTC')} {shiftStatus?.status}
                                  </Flex>
                                  <Text fontWeight="500" fontSize="0.65rem" paddingLeft="24px">
                                    {shiftStatus?.subStatus}
                                  </Text>
                                </Flex>
                              ) : null}
                            </Td>
                            <Td textAlign="start" sx={tableCellStyles} fontSize="0.875rem" fontWeight="600">
                              {FormatNdProName(shift)}
                            </Td>
                          </Tr>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </Tbody>
            </Table>
          </div>
        </ModalBody>

        <ModalFooter display="flex" width="100%" gap="0.5rem">
          <Button variant="ghost" onClick={handleClose}>
            Cancel
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              trackPrintWeekEvent({
                startDate: moment(startDate).format('dddd, MMM Do YYYY'),
                endDate: moment(endDate).format('dddd, MMM Do YYYY'),
              });
              handlePrint();
            }}
          >
            <Flex alignItems="center">
              <IoPrintOutline />
              Print
            </Flex>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PrintPreviewModal;
