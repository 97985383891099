import React from 'react';
import {Box, Flex, Text, useDisclosure} from '@chakra-ui/react';
import moment from 'moment';
import {GoPeople} from 'react-icons/go';
import {CalendarIcon, TimeIcon} from '@chakra-ui/icons';
import uuid from 'react-uuid';
import {useLazyGetShiftDetails} from '../../../../shared/gql/CalendarShifts/queries';
import ShiftInformationDrawer from '../../CalendarPage/ShiftInformationDrawer/ShiftInformationDrawer';
import {useTimeZone} from '../appSelectors';

type ShiftCardData = {
  shiftDates?: {
    startTime: string;
    shiftId: string;
    endTime: string;
    openings: number;
    openingIds: string[];
  }[];
  shiftCardUnit: {
    label: string;
    value: string;
  };
  shiftCardOpenings: Array<{
    localStartDate: string;
    openings: number;
  }>;
  shiftCardStartTime: string;
  shiftCardEndTime: string;
  shiftCardPosition: Array<{
    name: string;
    rate: number;
    adjustmentRate: number;
    id: string;
  }>;
};

type Props = {
  shift: ShiftCardData;
};

const ShiftCard = ({shift}: Props) => {
  const timeZone = useTimeZone();
  const sortedPositions = [...shift.shiftCardPosition].sort((a, b) => a.name.localeCompare(b.name));

  const sortedOpenings = [...shift.shiftCardOpenings].sort(
    (a, b) => new Date(a.localStartDate).getTime() - new Date(b.localStartDate).getTime()
  );
  const drawerRef = React.useRef(null);
  const {getShiftDetails, data, loading} = useLazyGetShiftDetails();

  const {isOpen: isInfoDrawerOpen, onOpen: onInfoDrawerOpen, onClose: onInfoDrawerClose} = useDisclosure();

  return (
    <>
      <Box
        borderRadius="4px"
        border="1px solid #F1F1F1"
        padding={['1rem', '1rem 1.5rem']}
        cursor="pointer"
        backgroundColor="white"
        boxShadow="0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
      >
        <Flex justifyContent="space-between" marginBottom=".5rem">
          <Box flexGrow={1} maxWidth="60%">
            <Flex gap=".5rem">
              <Text color="#313871" fontWeight="600" fontSize="16px" marginBottom=".2rem">
                {shift.shiftCardUnit.label.toUpperCase()}
              </Text>
            </Flex>
            <Flex gap=".4rem">
              <TimeIcon />
              <Text fontSize="12px" color="#313871">
                {moment(shift.shiftCardStartTime, 'HH:mm').format('h:mm A')} -{' '}
                {moment(shift.shiftCardEndTime, 'HH:mm').format('h:mm A')}
              </Text>
            </Flex>
          </Box>
          <Flex minWidth="100px" flexWrap="wrap" flexDirection="row" flexGrow={1} maxWidth="40%">
            {sortedPositions.map((pos: any, index: number, array: any[]) => (
              <Text
                marginLeft=".1rem"
                marginRight=".1rem"
                color="#313871"
                fontWeight="600"
                fontSize="14px"
                marginBottom=".2rem"
                key={uuid()}
              >
                {index === array.length - 1 && array.length > 1 ? `or ${pos.name}` : pos.name}
              </Text>
            ))}
          </Flex>
        </Flex>

        <Flex flexDirection="column" gap=".5rem">
          {sortedOpenings.map((opening: any) => (
            <Flex justifyContent="space-between" key={uuid()}>
              <Flex
                gap=".4rem"
                _hover={{
                  color: '#313871',
                  textDecoration: 'underline',
                }}
                onClick={() => {
                  const datesFromShiftDates = shift?.shiftDates?.find(
                    (shiftDate: {startTime: string}) =>
                      moment.tz(shiftDate?.startTime, timeZone).format('YYYY-MM-DD') === opening.localStartDate
                  );

                  getShiftDetails({
                    variables: {
                      shiftId: datesFromShiftDates?.shiftId,
                    },
                  });
                  onInfoDrawerOpen();
                }}
              >
                <CalendarIcon />
                <Text fontSize="12px" color="#313871">
                  {moment.utc(opening.localStartDate, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                </Text>
              </Flex>
              <Flex gap=".4rem" minWidth="100px">
                <GoPeople />
                <Text fontSize="12px" color="#313871">
                  {opening.openings > 1 ? `${opening.openings} openings` : `${opening.openings} opening`}
                </Text>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Box>
      {data?.getShiftDetail && (
        <ShiftInformationDrawer
          isOpen={isInfoDrawerOpen}
          onClose={onInfoDrawerClose}
          finalFocusRef={drawerRef}
          shift={data.getShiftDetail}
          position={sortedPositions?.[0]?.name}
          unitName={shift.shiftCardUnit.label}
          startDate={new Date(sortedOpenings?.[0]?.localStartDate)}
          endDate={new Date(sortedOpenings?.[0]?.localStartDate)}
          searchAllShifts={() => {}}
          getShiftDetails={getShiftDetails}
          isLoading={loading}
        />
      )}
    </>
  );
};

export default ShiftCard;
