import React, {useEffect} from 'react';
// form
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';

// chakra
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Box,
  FormLabel,
  Text,
} from '@chakra-ui/react';

import useAppSelector from '../../../../shared/hooks/useAppSelector';
import useCreateUser, {useUpdateUser} from '../../../../shared/gql/Users/mutations';
import {UsersState} from '../../../../shared/slices/usersSlice';
import useFacilityIdFromToken from '../../utils/tokenUtils';

import FormInput from './FormInput/FormInput';
import NDReactSelect from '../../../../shared/components/NDReactSelect/NDReactSelect';

import {Props} from './InviteOrUpdateUser.types';
import {SingleValue} from '../../../Shifts/CreateShift/CreateShiftForm/CreateShiftForm.types';
import {trackUserPageInvite} from "../../../../shared/mixpanel/page-views";

const InviteOrUpdateUser = ({
  title,
  body,
  primaryBtnText,
  secondaryBtnText,
  primaryBtnVariant,
  handleIsModalOpen,
  displayToast,
  isEditUser,
  setIsEditUser,
  refetchUsers,
}: Props) => {
  const schema = yup.object().shape({
    email: yup
      .string()
      .required('Email is required')
      .test('is-valid-email', 'Must be an email address', value => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
      }),
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    role: yup.object().required('Role is required'),
  });

  const selectedUser = useAppSelector((state: {usersData: UsersState}) => state.usersData.user);
  const facilityIdFromDecodedToken = useFacilityIdFromToken();

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    control,
    formState: {errors},
  } = useForm({resolver: yupResolver(schema), mode: 'onSubmit'});

  const handleRoleSelection = (e: SingleValue) => {
    setValue('role', e);
  };

  const {isOpen, onOpen, onClose} = useDisclosure();

  const [createUserFn] = useCreateUser(
    {
      variables: {
        createUser: {
          facilityId: facilityIdFromDecodedToken ?? 'facilityId from token not found',
          email: getValues('email'),
          firstName: getValues('firstName'),
          lastName: watch('lastName'),
          role: watch('role')?.value,
        },
      },
    },
    undefined,
    refetchUsers
  );

  const valuesIfSelectedUser = (field: string) => (selectedUser ? watch(field) : '');

  const [updateUserFn] = useUpdateUser(
    {
      variables: {
        updateUser: {
          userId: selectedUser?.userId ?? '',
          firstName: valuesIfSelectedUser('firstName'),
          lastName: valuesIfSelectedUser('lastName'),
          email: valuesIfSelectedUser('email'),
          role: watch('role')?.value,
          facilityId: facilityIdFromDecodedToken ?? 'facilityId from token not found',
        },
      },
    },
    undefined,
    refetchUsers
  );

  // ToDo: Add a loading state. Will need the figma ui for this.

  const onSubmit = async (data: any) => {
    if (!facilityIdFromDecodedToken) {
      displayToast('Failed to fetch facility ID', 'error');
      return;
    }
    try {
      let response;
      if (isEditUser) {
        response = await updateUserFn();
      } else {
        response = await createUserFn();
      }

      if (response.data) {
        displayToast(isEditUser ? 'User Updated' : 'Invite sent', 'success');
        onClose();
        handleIsModalOpen(false);
        if (isEditUser) {
          setIsEditUser(false);
        }
      } else if (!response.data) {
        displayToast(isEditUser ? 'Update failed' : 'Invite failed', 'error');
      }
    } catch (error) {
      console.log(isEditUser ? 'onUpdateError:' : 'onCreateError:', error);
    }
    console.log('data:', data);
  };

  useEffect(() => {
    trackUserPageInvite();
  }, [])
  useEffect(() => {
    onOpen();
    if (isEditUser) {
      setValue('firstName', selectedUser?.firstName);
      setValue('lastName', selectedUser?.lastName);
      setValue('email', selectedUser?.email);
      setValue('role', {label: selectedUser?.role, value: selectedUser?.role});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditUser, onOpen]);
  const handleCloseModal = () => {
    onClose();
    handleIsModalOpen(false);
    setIsEditUser(false);
  };

  return (
    <ChakraModal isOpen={isOpen} onClose={handleCloseModal} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="indigo.900" fontWeight="700" w="98%">
          {title}
        </ModalHeader>
        <ModalCloseButton
          type="button"
          onClick={() => {
            handleCloseModal();
          }}
        />
        <ModalBody marginBottom="1rem">{body}</ModalBody>

        <ModalBody>
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <FormInput
                  register={register}
                  label="Email Address"
                  placeholder="Enter email address"
                  name="email"
                  errors={errors}
                />
                <FormInput
                  register={register}
                  label="First Name"
                  placeholder="Enter the first name of the user"
                  name="firstName"
                  errors={errors}
                />
                <FormInput
                  register={register}
                  label="Last Name"
                  placeholder="Enter the last name of the user"
                  name="lastName"
                  errors={errors}
                />
                <FormLabel fontWeight="400" htmlFor="role">
                  Role
                </FormLabel>
                <Controller
                  name="role"
                  control={control}
                  render={({field}) => (
                    <NDReactSelect
                      {...register('role')}
                      {...field}
                      isMulti={false}
                      placeholder="Enter the role of the user"
                      errors={errors}
                      options={[
                        {label: 'Scheduler', value: 'Scheduler'},
                        {label: 'Viewer', value: 'Viewer'},
                        {label: 'Admin', value: 'Admin'},
                      ]}
                      onChange={(e: SingleValue) => {
                        handleRoleSelection(e);
                      }}
                    />
                  )}
                />
                {errors?.role && (
                  <Text fontSize="12px" color="#C53030" marginTop=".5rem">
                    {errors.role.message as string}
                  </Text>
                )}
                <Text color="rgba(113, 128, 150, 1)" size="0.75rem" marginTop=".5rem" marginBottom=".5rem">
                  Give your users Admin access if you would like them to add and delete other users
                </Text>
                <ModalFooter paddingRight="0">
                  <Button
                    width="6.25rem"
                    variant="secondary"
                    mr={3}
                    onClick={() => {
                      handleCloseModal();
                      setIsEditUser(false);
                    }}
                    type="button"
                  >
                    {secondaryBtnText}
                  </Button>
                  <Button width="6.25rem" type="submit" variant={primaryBtnVariant}>
                    {primaryBtnText}
                  </Button>
                </ModalFooter>
              </Box>
            </form>
          </Box>
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};

export default InviteOrUpdateUser;
