import React, {useMemo} from 'react';
import {Box, Flex, Icon, Text} from '@chakra-ui/react';
import moment from 'moment';
import {FaHeart} from 'react-icons/fa';
import {MappedShiftV2} from '../Shift.types';
import {useTimeZone} from '../CreateShift/appSelectors';
import {checkShiftStatus} from './helpers/helpers';
import useAppSelector from '../../../shared/hooks/useAppSelector';
import {ReduxState} from '../../../shared/types';

type Props = {
  counts: any;
  day: string;
  mappedShiftsForDay: MappedShiftV2[];
  handleOpenDrawer: (shifts: MappedShiftV2[], counts: any, day: string) => void;
  selectedUnitFilter: {unitId: string; positions: string[]}[];
};

const MonthlyConfirmedShiftsListCard = ({
  counts,
  day,
  mappedShiftsForDay,
  handleOpenDrawer,
  selectedUnitFilter,
}: Props) => {
  const timeZone = useTimeZone();

  const facilityUnits = useAppSelector(
    (state: ReduxState) => state.shiftTemplates.facilityUnits?.getFacilityUnitPositionRate.units
  );

  const sortedShifts = useMemo(
    () =>
      mappedShiftsForDay
        ?.filter(
          innerShift =>
            selectedUnitFilter?.find(unit => unit?.unitId === innerShift.unitId) &&
            selectedUnitFilter
              ?.find(unit => unit.unitId === innerShift.unitId)
              ?.positions?.includes(innerShift?.positionDetail?.[0]?.id)
        )
        ?.sort((a, b) => Number(new Date(a.startTime).getTime()) - Number(new Date(b.startTime).getTime())),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mappedShiftsForDay, selectedUnitFilter, timeZone]
  );

  const filteredShifts = sortedShifts?.map(shift => {
    const ndPros = shift?.openings?.map(opening => opening?.ndProId);
    const filteredApplicants = shift?.applicants
      ?.filter((applicant, index, self) => self.findIndex(t => t.ndProId === applicant.ndProId) === index)
      ?.filter(applicant => !ndPros?.includes(applicant?.ndProId));

    const openedOpenings = shift?.openings
      ? shift.openings.filter(opening => ['Opened', 'OpenedWithApplicants'].includes(opening.status))
      : [];

    const updatedOpenings = openedOpenings.map((opening, index) => {
      if (filteredApplicants && filteredApplicants?.length > index) {
        return {
          ...opening,
          status: 'OpenedWithApplicants',
        };
      }

      return {
        ...opening,
        status: 'Opened',
      };
    });

    const otherOpenings = shift?.openings
      ? shift.openings.filter(opening => !['Opened', 'OpenedWithApplicants'].includes(opening.status))
      : [];

    const filteredOpening = [...otherOpenings, ...updatedOpenings];

    const filledOpenings = filteredOpening?.filter(opening =>
      ['Completed', 'Confirmed', 'PendingReview', 'PendingClockIn', 'PendingClockOut']?.includes(opening?.status)
    );

    return {...shift, openings: filledOpenings};
  });

  // Create a mapping of the desired order
  const orderMap = {
    filledCompletely: 0,
    filledPartially: 1,
    actionRequired: 2,
    upcomingUnfilled: 3,
    pastUnfilled: 4,
    cancelled: 5,
  };

  const sortBasedOnStatus = filteredShifts
    ?.filter(shift => ['filledCompletely', 'filledPartially']?.includes(checkShiftStatus(shift, timeZone)))
    ?.sort(
      (a, b) => (orderMap[checkShiftStatus(a, timeZone) || ''] || 0) - (orderMap[checkShiftStatus(b, timeZone)] || 0)
    );

  let count = 0;

  if (sortBasedOnStatus?.length > 0) {
    return (
      <Flex flexDirection="column" justifyContent="flex-start" height="calc(100% - 30px)" gap="1px">
        <Flex
          alignItems="center"
          justifyContent="flex-start"
          padding="5px"
          gap="0.2rem"
          flexDirection="column"
          fontSize="12px"
          height="calc(100% - 20px)"
          overflow="hidden"
          minHeight="calc(100% - 30px)"
          onClick={e => {
            e?.stopPropagation();
            handleOpenDrawer(mappedShiftsForDay, counts, day);
          }}
        >
          {sortBasedOnStatus?.map(shift =>
            shift?.openings?.map(opening => {
              const unitDetails = facilityUnits?.find(unit => unit?.id === shift?.unitId);
              if (count >= 5) return null;
              count += 1;
              return (
                <Box padding="0.12rem" backgroundColor="#5AC68D4D" color="#0000007A" borderRadius={2} width="100%">
                  <Flex alignItems="center" flexWrap="nowrap" width="100%">
                    <Flex
                      fontSize="10px"
                      overflow="hidden"
                      width="100%"
                      title={`${unitDetails?.name} / ${shift?.position} | ${opening?.ndProName}`}
                    >
                      <Text>
                        {moment(shift.startTime).format('h:mma')}-{moment(shift.endTime).format('h:mma')} |
                      </Text>
                      <Flex alignItems="center" gap="0.2rem" justifyContent="flex-start" overflow="hidden" flex="1">
                        <Text as="span" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                          {opening?.ndProName}
                        </Text>{' '}
                        {opening?.favorite && <Icon as={FaHeart} boxSize="10px" color="#FC8181" />}
                      </Flex>
                    </Flex>
                  </Flex>
                </Box>
              );
            })
          )}
        </Flex>

        <Text
          textDecoration="underline"
          fontSize="12px"
          color="#313871"
          height="15px"
          paddingLeft="5px"
          onClick={e => {
            e?.stopPropagation();
            handleOpenDrawer(mappedShiftsForDay, counts, day);
          }}
        >
          View all
        </Text>
      </Flex>
    );
  }

  return (
    <Flex
      height="calc(100% - 30px)"
      width="100%"
      padding="5px"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      {Number(counts?.filledCount) + Number(counts?.completedCount) ? (
        <Text
          _hover={{textDecoration: 'underline'}}
          fontSize="12px"
          color="#313871"
          textAlign="center"
          onClick={e => {
            e?.stopPropagation();
            handleOpenDrawer(mappedShiftsForDay, counts, day);
          }}
        >
          {Number(counts?.filledCount) + Number(counts?.completedCount)} confirmed shifts
        </Text>
      ) : (
        <Text fontSize="12px" color="#2D3748" fontWeight="500" textAlign="center">
          No confirmed shift
        </Text>
      )}
    </Flex>
  );
};

export default MonthlyConfirmedShiftsListCard;
