import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {useNavigate, useSearchParams} from 'react-router-dom';
import * as sentry from '@sentry/react';
import {Center, Flex, Spinner} from '@chakra-ui/react';
import {useAuth0} from '@auth0/auth0-react';
import {useLoginAudit} from '../../shared/gql/Users/mutations';
import {ReduxState} from '../../shared/types/index';
import {useSessionUser} from '../Shifts/CreateShift/appSelectors';
import useAppSelector from '../../shared/hooks/useAppSelector';

const Home = () => {
  const urlParams = localStorage.getItem('legacyFidParam');
  const ndAdminUserId = localStorage.getItem('legacyNDAdminUserId');
  const authToken = useSelector((state: ReduxState) => state.auth.token);
  const {cutoffLegacyUi} = useFlags();
  const sessionUser = useSessionUser();
  const isAdmin = sessionUser?.ndAdmin;
  const isViewer = sessionUser?.role === 'Viewer';
  const navigate = useNavigate();
  const ldContext = useAppSelector((state: any) => state?.usersData?.ldContext);
  const [loginAuditMutation] = useLoginAudit();
  const [searchParams] = useSearchParams();
  const {isAuthenticated} = useAuth0();

  useEffect(() => {
    if (authToken !== '' && sessionUser?.userId && sessionUser?.facilityName && ldContext) {
      if (urlParams && !isAdmin) {
        sentry.withScope(scope => {
          scope.clear();
          scope.setLevel('warning');
          scope.setTag('user_email', sessionUser?.email);
          scope.setTag('adminUserId', ndAdminUserId);
          scope.setTag('userId', sessionUser?.userId);
          scope.setTag('userId', sessionUser?.userId);
          scope.setTag('Name', `${sessionUser?.firstName} ${sessionUser?.lastName}`);
          sentry.captureMessage('Admin attempted to access a facility but canAssumeIdentity is false', 'warning');
        });
      }
      navigate('/calendar?view=weekly');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authToken,
    cutoffLegacyUi,
    isAdmin,
    ldContext,
    navigate,
    sessionUser?.facilityName,
    sessionUser?.userId,
    isViewer,
  ]);

  useEffect(() => {
    if (isAuthenticated && searchParams.get('source') === 'auth0') {
      loginAuditMutation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (authToken !== '') {
    if (!sessionUser?.userId) {
      return (
        <Center w="100vw" h="100vh">
          <Flex margin="0" position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#38427E" size="xl" />
          </Flex>
        </Center>
      );
    }
  }

  return null;
};
export default Home;
