import React from 'react';
import {useIntercom} from 'react-use-intercom';
import {Box, Flex, CloseButton, useColorModeValue, BoxProps, useDisclosure} from '@chakra-ui/react';
import {IconType} from 'react-icons';
import {AiOutlineCalendar, AiOutlineMail} from 'react-icons/ai';
import {TbMessageCircle2} from 'react-icons/tb';
import {CiViewTable} from 'react-icons/ci';
import {BiUser} from 'react-icons/bi';
import {FiSettings, FiSave} from 'react-icons/fi';
import {LiaWpforms} from 'react-icons/lia';
// import moment from 'moment';

import useAppSelector from '../../../hooks/useAppSelector';
import ndLogo from '../../../../assets/logo/logo-white-1.svg';
import NavItem from '../NavItem/NavItem';
import './SidebarContent.scss';
import ContactUs from '../ContactUs/ContactUs';
import useGetAllLegacyChannels from '../../../gql/messages/queries';
import {useFacilityId, useLegacyFacilityId} from '../../../../routes/Shifts/CreateShift/appSelectors';
import ActivityHistoryDrawer from '../../ActivityHistory/ActivityHistoryDrawer';

type SidebarProps = BoxProps & {
  onClose: () => void;
};

type LinkItemProps = {
  name: string;
  icon: IconType;
  url?: string;
  reloadDocument?: boolean;
  badge?: number;
  onClick?: () => void;
};

const SidebarContent = ({onClose, ...rest}: SidebarProps) => {
  const legacyFacilityId = useLegacyFacilityId();
  const facilityId = useFacilityId();
  const userRole = useAppSelector((state: any) => state?.usersData?.sessionUser?.role);
  const sessionUser = useAppSelector((state: any) => state?.usersData?.sessionUser);
  const ndAdminUserId = localStorage.getItem('legacyNDAdminUserId');
  const userId = sessionUser?.ndAdmin ? ndAdminUserId : legacyFacilityId;

  // fetch the channel on losd to get the unread count
  const channelsData = useAppSelector(state => state.allChannels.data);
  useGetAllLegacyChannels();
  // get unread count for this user
  const unreadCount = channelsData?.reduce((accumulator, channel) => {
    const {members} = channel;
    const member = members.find(
      (channelMember: any) => channelMember.user.userModel === 'facility' && channelMember.user.userId === userId
    ) || {unreadCount: 0};
    return accumulator + (member.unreadCount > 0 ? 1 : 0);
  }, 0);

  const {show} = useIntercom();
  const borderRightColor = useColorModeValue('gray.200', 'gray.700');

  const isScheduler = userRole === 'Scheduler';

  const linkItems: Array<LinkItemProps> = [
    {
      name: 'Calendar',
      icon: AiOutlineCalendar,
      url: `/calendar?view=weekly`,
    },
    {
      name: 'Create Shift',
      icon: CiViewTable,
      url: '/shifts/create',
    },

    {name: 'Shift Templates', icon: FiSave, url: '/shift-templates'},

    ...(!isScheduler ? [{name: 'Users', icon: BiUser, url: '/users', reloadDocument: !!sessionUser?.ndAdmin}] : []),

    {
      name: 'Messages',
      icon: TbMessageCircle2,
      url: '/messages',
      reloadDocument: !!sessionUser?.ndAdmin,
      badge: unreadCount,
    },
    {
      name: 'Settings',
      icon: FiSettings,
      url: '/settings',
    },
    {
      name: 'Hiring Form',
      icon: LiaWpforms,
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSfEfPkP4IR2-F0w3f5LTkjb99lHYQoRbVI-deO5X8mW3sm4Pw/viewform',
    },
    {
      name: 'Contact Us',
      icon: AiOutlineMail,
      onClick: show,
    },
  ];

  const {isOpen: isHistoryDrawerOpen, onOpen: onHistoryDrawerOpen, onClose: onHistoryDrawerClose} = useDisclosure();
  const isInternalAdmin = useAppSelector(
    state => state?.auth?.decodedToken[`https://nursedash.com/custom`]?.canAssumeIdentity
  );
  return (
    <Box
      transition="3s ease"
      bg="#313871"
      borderRight="1px"
      borderRightColor={borderRightColor}
      w={{base: 'full', md: 60}}
      pos="fixed"
      h="full"
      paddingTop="2rem"
      zIndex="99"
      {...rest}
    >
      <Flex h="3rem" alignItems="center" mx="8" justifyContent="space-between" marginBottom="2.5rem">
        <Flex onDoubleClick={() => isInternalAdmin && onHistoryDrawerOpen()}>
          <img className="nd-logo" src={ndLogo} alt="NurseDash logo" />
        </Flex>
        <CloseButton display={{base: 'flex', md: 'none'}} onClick={onClose} color="white" />
      </Flex>
      {linkItems.map(link => {
        if (link.url) {
          return (
            <NavItem
              key={link.name}
              icon={link.icon}
              url={link.url}
              reloadDocument={link.reloadDocument}
              badge={link.badge}
            >
              {link.name}
            </NavItem>
          );
        }
        return (
          <ContactUs key={link.name} icon={link.icon} onClick={link.onClick}>
            {link.name}
          </ContactUs>
        );
      })}
      <ActivityHistoryDrawer
        isOpen={isHistoryDrawerOpen}
        onClose={onHistoryDrawerClose}
        resourceId={facilityId || ''}
        resourceType="FACILITY"
        filter={{type: ['LOGIN_ND', 'LOGIN_FACILITY', 'FACILITY_ORIENTATION_UPLOAD']}}
      />
    </Box>
  );
};

export default SidebarContent;
