import {TimeIcon, WarningIcon} from '@chakra-ui/icons';
import {Flex, Icon, Text} from '@chakra-ui/react';
import React from 'react';
import {FaCheckCircle, FaHeart, FaRegStar, FaStar} from 'react-icons/fa';
import uuid from 'react-uuid';
import {OpeningsV2} from '../../../shared/types';

type Props = {
  opening: OpeningsV2;
  isPastShift: boolean;
};

const NdProListItem = ({opening, isPastShift}: Props) => {
  const formatName = (name: string, status: string, isFavorite?: boolean) => {
    // const [firstName, ...lastName] = name.split(' ');

    const getFontColor = (shiftStatus: string) => {
      switch (shiftStatus) {
        case 'Confirmed':
        case 'Completed':
        case 'PendingReview':
        case 'PendingClockIn':
          return '#46BF7E';
        case 'PendingCheckIn':
        case 'OpenedWithApplicants':
        case 'Selected':
          return '#405FF2';
        default:
          return '#46BF7E';
      }
    };

    const fontColor = getFontColor(status);

    return (
      <Flex alignItems="center" gap="2px" justifyContent="flex-start" title={name}>
        <Text
          as="span"
          fontSize="12px"
          fontWeight="600"
          color={fontColor}
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          // width='calc(100% - 16px)'
        >
          {name}
        </Text>
        {isFavorite && <Icon as={FaHeart} color="#FC8181" />}
      </Flex>
    );
  };

  const getStatusText = (status: string, ndProName?: string, isFavorite?: boolean) => {
    if (isPastShift) {
      switch (status) {
        case 'PendingReview':
        case 'Completed':
        case 'PendingClockIn':
        case 'PendingCheckIn':
          return formatName(ndProName ?? '', status, isFavorite);
        default:
          return '';
      }
    } else {
      switch (status) {
        case 'OpenedWithApplicants':
          return (
            <Text fontSize="12px" fontWeight="600" color="#405FF2">
              Select Applicants
            </Text>
          );
        case 'Opened':
          return (
            <Text fontSize="12px" fontWeight="600" color="grey">
              Searching...
            </Text>
          );
        default:
          return formatName(ndProName ?? '', status, isFavorite);
      }
    }
  };

  const renderStatusIcon = (status: string) => {
    switch (status) {
      case 'Completed':
        return <Icon as={FaStar} color="yellow.400" mt="-1px" boxSize="12px" mr="5px" />;
      case 'Confirmed':
        return <Icon as={FaCheckCircle} color="green.400" mt="-1px" boxSize="14px" ml="-1px" mr="3px" />;
      case 'PendingReview':
        return <Icon as={FaRegStar} color="#405FF2" mt="-1px" boxSize="12px" mr="5px" />;
      case 'PendingClockIn':
        return <TimeIcon color="green.400" ml="-2px" mr="2px" boxSize="12px" padding="1px" />;
      case 'Selected':
      case 'PendingCheckIn':
        return <TimeIcon color="#405FF2" ml="-2px" mr="2px" boxSize="12px" padding="1px" />;
      case 'OpenedWithApplicants':
        return <WarningIcon color="#405FF2" ml="-2px" mr="2px" boxSize="12px" padding="1px" />;
      default:
        return null;
    }
  };

  const displayText = getStatusText(opening.status, opening.ndProName, opening.favorite);
  const shouldDisplayIcon = displayText !== '';

  return (
    <Flex className="shift-nd-list" key={uuid()} pb="0.3125rem" w="100%" alignItems="center" paddingLeft="0.5rem">
      {shouldDisplayIcon && renderStatusIcon(opening.status)}
      <Text
        fontSize="0.75rem"
        fontWeight="600"
        ml="0.125rem"
        color={displayText ? 'black' : '#808080'}
        width="calc(100% - 20px)"
      >
        {displayText}
      </Text>
    </Flex>
  );
};
export default NdProListItem;
